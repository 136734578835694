/* Spinners */

.loader--spinner {
  margin: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  background: #00000038;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader--spinner div {
  animation: loader--spinner 1.2s linear infinite;
  transform-origin: 30px 30px;
}

.loader--spinner div:after {
  display: block;
  position: absolute;
  top: 1px;
  left: 27px;
  border-radius: 50%;
  content: ' ';
  height: 35px;
  margin: 60px;
  width: 35px;
}

.loader--spinner div:nth-child(1) {
  animation-delay: -1.1s;
  transform: rotate(0deg);
}

.loader--spinner div:nth-child(2) {
  animation-delay: -1s;
  transform: rotate(30deg);
}

.loader--spinner div:nth-child(3) {
  animation-delay: -0.9s;
  transform: rotate(60deg);
}

.loader--spinner div:nth-child(4) {
  animation-delay: -0.8s;
  transform: rotate(90deg);
}

.loader--spinner div:nth-child(5) {
  animation-delay: -0.7s;
  transform: rotate(120deg);
}

.loader--spinner div:nth-child(6) {
  animation-delay: -0.6s;
  transform: rotate(150deg);
}

.loader--spinner div:nth-child(7) {
  animation-delay: -0.5s;
  transform: rotate(180deg);
}

.loader--spinner div:nth-child(8) {
  animation-delay: -0.4s;
  transform: rotate(210deg);
}

.loader--spinner div:nth-child(9) {
  animation-delay: -0.3s;
  transform: rotate(240deg);
}

.loader--spinner div:nth-child(10) {
  animation-delay: -0.2s;
  transform: rotate(270deg);
}

.loader--spinner div:nth-child(11) {
  animation-delay: -0.1s;
  transform: rotate(300deg);
}

.loader--spinner div:nth-child(12) {
  animation-delay: 0s;
  transform: rotate(330deg);
}

@keyframes loader--spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.loader--spinner div:after {
  background: #f17c2b;
}

.custom-loader {
  height: calc(100vh - 385px);
  .loader--spinner {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    & > div:after {
      display: block;
      position: absolute;
      top: 1px;
      left: 27px;
      border-radius: 50%;
      content: ' ';
      height: 30px;
      margin: 50px;
      width: 30px;
    }
  }
}
