.lh {
  &--initial {
    line-height: initial;
  }
  &--0 {
    line-height: 0;
  }
  &--15 {
    line-height: 15px;
  }
  &--16 {
    line-height: 16px;
  }
  &--19 {
    line-height: 19px;
  }
  &--20 {
    line-height: 20px;
  }
  &--21 {
    line-height: 21px;
  }
  &--22 {
    line-height: 22px;
  }
  &--23 {
    line-height: 23px;
  }
  &--24 {
    line-height: 24px;
  }
  &--25 {
    line-height: 25px;
  }
  &--27 {
    line-height: 27px;
  }
  &--28 {
    line-height: 28px;
  }
  &--29 {
    line-height: 29px;
  }
  &--32 {
    line-height: 32px;
  }
  &--35 {
    line-height: 35px;
  }
  &--36 {
    line-height: 36px;
  }
  &--40 {
    line-height: 40px;
  }
  &--42 {
    line-height: 42px;
  }
  &--45 {
    line-height: 45px;
  }
  &--49 {
    line-height: 49px;
  }

  &--52 {
    line-height: 52px;
  }

  @media (max-width: 767px) {
    &--sm-27 {
      line-height: 27px;
    }
    &--sm-42 {
      line-height: 42px;
    }
  }
}
