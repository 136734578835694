.call-outer {
  @media (max-width: 767px) {
    text-align: center;
  }
  .upgrade-business-phone-system-sec {
    padding: 125px 0 60px 0;
    @media (max-width: 767px) {
      padding: 30px 0;
    }
    .call-banner-img {
      img {
        margin-right: -235px;

        margin-left: auto;
        display: block;
        @media (max-width: 767px) {
          margin-right: 0;
          margin-top: 30px;
        }
      }
    }
  }
  .communication-system-sec {
    padding: 60px 0;
    @media (max-width: 767px) {
      padding: 30px 0;
    }
  }
  .manage-device-features-sec {
    padding: 60px 0;
    @media (max-width: 767px) {
      padding: 30px 0;
    }
    .manage-device-video {
      video {
        border-radius: 40px;
      }
    }
  }
  .work-together-sec {
    padding: 60px 0;
    @media (max-width: 767px) {
      padding: 30px 0;
    }
    .questions-about-nxatel {
      .card {
        margin-bottom: 15px;
        .card-header {
          background: #fff;
          border-radius: 6px !important;
          padding: 8px 4px;
          border: none;
          box-shadow: 0px 0px 6px rgb(0 0 0 / 9%);
          .btn {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #696871;
            text-align: left;
            font-family: $dm-sans;
            &.collapsed {
              .collapse-arrow {
                -webkit-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                -ms-transform: rotate(0deg);
                transform: rotate(0deg);
              }
            }
            .collapse-arrow {
              -webkit-transform: rotate(180deg);
              -moz-transform: rotate(180deg);
              -o-transform: rotate(180deg);
              -ms-transform: rotate(180deg);
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
  .try-free-days-sec {
    padding: 60px 0;
    @media (max-width: 767px) {
      padding: 30px 0;
    }
  }
}
