$dm-sans: "DM Sans", sans-serif;
$poppins: "Poppins", sans-serif;
$inter: "Inter", sans-serif;

$orange: #f17c2b;
$dark-orange-1: #dd7124;
$dark-orange-2: #c06322;
$orange-EF9F58: #ef9f58;
$orange-f895: #f8be95;
$black: #000;
$black-191b: #19191b;
$black-2129: #212529;
$black-33: #333333;
$black-41d4: #41414d;
$black-40: #404040;
$black-1a1d: #1a1c1d;
$black-6: #3c4858;
$dark-gray-4c65: #4c5665;
$dark-gray-1: #6f8ba4;
$dark-gray-2: #6b778c;
$dark-gray-3: #47525e;
$dark-gray-4: #566789;
$purple-6f99: #6f6c99;
$purple-4c66: #4c4c66;
$purple-6e93: #6e6893;
$purple-80a3: #8083a3;
$purple-6b9a: #6b779a;
$purple-3f4e: #393f4e;
$gray-e9ef: #e9ecef;
$gray-c6c6c6: #c6c6c6;
$gray-EFEFEF: #efefef;
$gray-F7F7F7: #f7f7f7;
$chat-gray-bg: #d7dade;
$light-gray-ee: #eeeeee;
$gray-f5fa: #f5f6fa;
$gray-dd: #ddd;
$gray-f0: #f0f0f0;
$gray-f1: #f1f1f1;
$gray-f2: #f2f2f2;
$gray-fa: #fafafa;
$gray-e6: #e6e6e6;
$gray-cc: #cccccc;
$gray-c4: #c4c4c4;
$gray-4d: #4d4d4d;
$gray-c0: #c0c0c0;
$gray-ec: #ececec;
$gray-c7: #c7c7c7;
$gray-dd: #dddddd;
$gray-da: #dadada;
$gray-d8: #d8d8d8;
$gray-d9: #d9d9d9;
$gray-a4: #a4a4a4;
$gray-cf: #cfcfcf;
$gray-bebebe: #bebebe;
$gray-9a: #9a9a9a;
$gray-88: #888888;
$gray-80: #808080;
$gray-3b: #3b3b3b;
$gray-5d: #5d5d5d;
$gray-E8: #e8e8e8;
$gray-B4: #b4b4b4;
$gray-7e: #7e7e7e;
$gray-97: #979797;
$gray-66: #666666;
$gray-59: #595959;
$gray-6971: #696871;
$gray-5b7d: #5b6a7d;
$gray-778f: #77838f;
$gray-7c87: #7c8087;
$gray-3b6e: #3b566e;
$gray-4757: #474a57;
$gray-64: #646464;
$gray-9c: #9c9c9c;
$gray-f4: #f4f4f4;
$gray-9db7: #9dabb7;
$gray-394e: #393f4e;
$gray-d2ec: #d2daec;
$gray-e7ef: #e7e7ef;
$gray-dbf0: #dbe1f0;
$gray-f0fb: #f0f5fb;
$gray-eff3: #eeeff3;
$gray-icon: #a4a7ac;
$gray-7b8a: #7b838a;
$gray-8a90: #8a8c90;
$gray-ecef: #ececef;
$gray-a6b7: #a6a4b7;
$gray-f9f: #F9FAFA;
$gray-icon-aac8: #aab2c8;
$inner-sidebar-gray-icon: #83899b;
$light-red: #ed454580;
$red: #ff0000;
$red-2: #ec4444;
$red-3: #ec4a4b;
$red-4: #e20f00;
$red-5: #ee0a0a;
$red-fc4a: #fc354a;
$red-D3381D: #d3381d;
$pdf-red: #bb0806;
$red-fe29: #fe2929;
$red-ec: #ec4444;
$red-f037: #f04037;
$red-feec: #feecec;
$white: #fff;
$light-blue: #e9effe;
$blue: #1f64ff;
$blue-23fe: #233afe;
$blue-2: #305cdc;
$blue-45fe: #457afe;
$blue-54d4: #5454d4;
$blue-1968: #193b68;
$blue-32cc: #3279cc;
$blue-1d3f: #1d293f;
$space-blue: #b1d4ff;
$space-blue-eefb: #eeeefb;
$space-blue-f1ec: #f1f9ec;
$space-blue-d5fd: #d5defd;
$space-blue-cef8: #cee1f8;
$light-orange: #ffe2ce;
$pink-ffef: #fffbef;
$pink-ffc5: #ffc3c5;
$skin-ffe0: #ffe0e0;
$dark-pink: #ff808b;
$light-yellow: #ffeab8;
$yellow-e8d5: #e8ded5;
$yellow: #ffd166;
$yellow-ff79: #ffdd79;
$rating-star-yellow: #ff9529;
$purple: #73348c;
$light-purple: #ccc3ff;
$purple-3665: #363565;
$purple-bcda: #bcb4da;
$light-purple: #ccc4ff;
$purple-D3E1: #d3d8e1;
$fade-orange: #f7caab;
$light-green: #cdffcd;
$green-f4dc: #f4fedc;
$green: #51a02f;
$green-2: #57b52b;
$green-3: #77d353;
$green-0732: #078032;
$green-4525: #459e25;
$green-0054: #009f54;
$green-c4cf: #c4dbcf;
$green-699A31: #699a31;
$blue-2aa1: #2a4da1;
$sky-blue: #d6eefc;
$navy-blue: #6b59cc;
$light-navy-blue: #ece9ff;
$light-teal-green: #15d6b34d;
$dark-teal-green: #15d6b3;
$gray-d5fd: #d5defd;
$blue-8bcc: #8b9ecc;
$active-A9CDEE: #a9cdee;
$chat-head: #e1efff;
