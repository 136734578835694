// @mixin flex-ac {
//   display: flex;
//   align-items: center;
// }
// @mixin flex-c-c {
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }
// @mixin flex-c-sb {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
// }
// @mixin flex-c-start {
//   display: flex;
//   align-items: center;
//   justify-content: flex-start;
// }
// @mixin flex-c-end {
//   display: flex;
//   align-items: center;
//   justify-content: flex-end;
// }
// @mixin flex-start-sb {
//   display: flex;
//   align-items: flex-start;
//   justify-content: space-between;
// }
// @mixin flex-start-c {
//   display: flex;
//   align-items: flex-start;
//   justify-content: center;
// }
// @mixin flex-start-start {
//   display: flex;
//   align-items: flex-start;
//   justify-content: flex-start;
// }

.d {
  &--grid {
    display: grid;
  }
}
