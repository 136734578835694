.h {
  &--fit-content {
    height: fit-content;
  }
  &-0 {
    height: 0;
  }
  &-20 {
    height: 20%;
  }

  &--2 {
    height: 2px;
  }
  &--5 {
    height: 5px;
  }
  &--12 {
    height: 12px;
  }
  &--15 {
    height: 15px;
  }
  &--16 {
    height: 16px;
  }
  &--17 {
    height: 17px;
  }
  &--18 {
    height: 18px !important;
  }
  &--20 {
    height: 20px !important;
  }
  &--23 {
    height: 23px !important;
  }
  &--24 {
    height: 24px !important;
  }
  &--25 {
    height: 25px;
  }

  &--27 {
    height: 27px;
  }
  &--28 {
    height: 28px;
  }
  &--30 {
    height: 30px !important;
  }
  &--33 {
    height: 33px;
  }

  &--34 {
    height: 34px;
  }
  &--35 {
    height: 35px;
  }
  &--36 {
    height: 36px;
  }
  &--38 {
    height: 38px;
  }
  &--39 {
    height: 39px;
  }
  &--40 {
    height: 40px;
  }
  &--41 {
    height: 41px;
  }
  &--42 {
    height: 42px;
  }
  &--43 {
    height: 43px;
  }
  &--44 {
    height: 44px;
  }
  &--45 {
    height: 45px;
  }
  &--46 {
    height: 46px !important;
  }
  &--47 {
    height: 47px !important;
  }
  &--50 {
    height: 50px;
  }
  &--55 {
    height: 55px;
  }
  &--58 {
    height: 58px;
  }
  &--60 {
    height: 60px;
  }
  &--70 {
    height: 70px;
  }
  &--71 {
    height: 71px;
  }
  &--75 {
    height: 75px;
  }
  &--85 {
    height: 85px;
  }
  &--100 {
    height: 100px;
  }
  &--110 {
    height: 110px;
  }
  &--115 {
    height: 115px;
  }
  &--140 {
    height: 140px;
  }
  &--155 {
    height: 155px;
  }
  &--160 {
    height: 160px;
  }
  &--170 {
    height: 170px;
  }
  &--179 {
    height: 179px;
  }
  &--180 {
    height: 180px;
  }
  &--185 {
    height: 185px;
  }
  &--192 {
    height: 192px;
  }
  &--210 {
    height: 210px;
  }
  &--270 {
    height: 270px;
  }
  &--289 {
    height: 289px;
  }
  &--350 {
    height: 350px;
  }
  &--min-auto {
    min-height: auto !important;
  }
  &--min-15 {
    min-height: 15px !important;
  }
  &--min-20 {
    min-height: 20px !important;
  }
  &--min-27 {
    min-height: 27px;
  }
  &--min-30 {
    min-height: 30px !important;
  }
  &--min-34 {
    min-height: 34px !important;
  }
  &--min-35 {
    min-height: 35px;
  }
  &--min-36 {
    min-height: 36px;
  }
  &--min-38 {
    min-height: 38px !important;
  }
  &--min-40 {
    min-height: 40px;
  }
  &--min-44 {
    min-height: 44px;
  }
  &--min-46 {
    min-height: 46px !important;
  }
  &--min-65 {
    min-height: 65px;
  }
  &--min-66 {
    min-height: 66px;
  }
  &--min-90 {
    min-height: 90px;
  }
  &--min-94 {
    min-height: 94px;
  }
  &--min-95 {
    min-height: 95px;
  }
  &--min-113 {
    min-height: 113px;
  }
  &--min-145 {
    min-height: 145px;
  }
  &--min-155 {
    min-height: 155px;
  }
  &--min-160 {
    min-height: 160px;
  }
  &--min-180 {
    min-height: 180px;
  }
  &--min-185 {
    min-height: 185px;
  }
  &--min-220 {
    min-height: 220px;
  }
  &--min-225 {
    min-height: 225px;
  }
  &--min-300 {
    min-height: 300px;
  }
  &--min-310 {
    min-height: 310px;
  }
  &--min-350 {
    min-height: 350px;
  }
  &--min-375 {
    min-height: 375px;
  }
  &--min-450 {
    min-height: 450px;
  }
  &--mx-47 {
    max-height: 47px;
  }
  &--mx-71 {
    max-height: 71px;
  }
  &--mx-145 {
    max-height: 145px;
  }
  &--mx-150 {
    max-height: 150px;
  }
  &--mx-300 {
    max-height: 300px;
  }
  &--mx-400 {
    max-height: 400px;
  }
  &--mx-450 {
    max-height: 450px;
  }
  &--mx-500 {
    max-height: 500px;
  }
  &--mx-600 {
    max-height: 600px;
  }
  @media (max-width: 767px) {
    &--sm-130 {
      min-height: 130px;
    }
    &--sm-min-auto {
      min-height: auto;
    }
  }
}
.vh-100 {
  height: 100vh;
}
