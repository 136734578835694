/* border-radius start */
.radius {
  &--3 {
    border-radius: 3px;
  }
  &--4 {
    border-radius: 4px;
  }
  &--5 {
    border-radius: 5px;
  }
  &--6 {
    border-radius: 6px !important;
  }
  &--8 {
    border-radius: 8px !important;
  }
  &--10 {
    border-radius: 10px !important;
  }
  &--12 {
    border-radius: 12px !important;
  }
  &--15 {
    border-radius: 15px !important;
  }
  &--16 {
    border-radius: 16px;
  }
  &--18 {
    border-radius: 18px !important;
  }
  &--20 {
    border-radius: 20px;
  }
  &--25 {
    border-radius: 25px;
  }
  &--40 {
    border-radius: 40px;
  }
  &--50 {
    border-radius: 50px !important;
  }
  &--top-left-right-10 {
    border-radius: 10px 10px 0 0 !important;
  }
  &--top-left-right-15 {
    border-radius: 15px 15px 0 0;
  }
  &--bottom-right-15 {
    border-radius: 0px 0px 15px 0px;
  }
  &--left-top-bottom-13 {
    border-radius: 13px 0 0 13px;
  }
  &--left-top-bottom-15 {
    border-radius: 15px 0 0 15px;
  }
  &--left-top-bottom-6 {
    border-radius: 6px 0 0 6px !important;
  }
  &--right-top-bottom-13 {
    border-radius: 0 13px 13px 0;
  }
  &--right-top-bottom-15 {
    border-radius: 0 15px 15px 0 !important;
  }
  &--top-bottom-right-6 {
    border-radius: 0px 6px 6px 0px !important;
  }
  &--top-bottom-right-30 {
    border-radius: 0px 30px 30px 0px;
  }
  &--left-right-top-45 {
    border-radius: 45px 45px 0px 0px;
  }
  &--top-right-30 {
    border-radius: 0 30px 0 0;
  }
  &--top-right-62 {
    border-radius: 0 62px 0 0;
  }
  &--16-0 {
    border-radius: 16px 0;
  }
  &--0-50 {
    border-radius: 0 50px;
  }
  &--0-100 {
    border-radius: 0 100px;
  }
}
