.border {
  &--none {
    border: none;
  }
  &--right-0 {
    border-right: none;
  }
  &--top-1 {
    border-top: 1px solid;
  }
  &--bottom-1 {
    border-bottom: 1px solid;
  }
  &--right-1 {
    border-right: 1px solid;
  }
  &--bottom-1-dashed {
    border-bottom: 1px dashed;
  }
  &--bottom-2 {
    border-bottom: 2px solid;
  }
  &--1 {
    border: 1px solid !important;
  }
  &--2 {
    border: 2px solid;
  }
  &--3 {
    border: 3px solid;
  }
  &--4 {
    border: 4px solid;
  }

  &--orange {
    border-color: $orange !important;
    &-hover:hover {
      border-color: $orange !important;
    }
  }
  &--rating-star-yellow {
    border-color: $rating-star-yellow !important;
  }
  &--gray-cc {
    border-color: $gray-cc !important;
  }
  &--gray-c4 {
    border-color: $gray-c4 !important;
  }
  &--gray-c7 {
    border-color: $gray-c7 !important;
  }
  &--gray-fa {
    border-color: $gray-fa !important;
  }
  &--light-gray-ee {
    border-color: $light-gray-ee !important;
  }
  &--gray-dd {
    border-color: $gray-dd !important;
  }
  &--transparent {
    border-color: transparent !important;
  }
  &--black {
    border-color: $black !important;
  }
  &--white {
    border-color: $white !important;
  }
  &--black-40 {
    border-color: $black-40 !important;
  }
  &--yellow {
    border-color: $yellow !important;
  }
  &--blue-45fe {
    border-color: $blue-45fe !important;
  }
  &--gray-d2ec {
    border-color: $gray-d2ec !important;
  }
  &--gray-3b6e {
    border-color: $gray-3b6e !important;
  }
  &--dark-gray-1 {
    border-color: $dark-gray-1 !important;
  }
  &--gray-f1 {
    border-color: $gray-f1;
  }
  &--gray-f0fb {
    border-color: $gray-f0fb;
  }
  &--gray-da {
    border-color: $gray-da;
  }
  &--chat-gray-bg {
    border-color: $chat-gray-bg;
  }
  &--gray-e6 {
    border-color: $gray-e6;
  }
  &--space-blue-cef8 {
    border-color: $space-blue-cef8;
  }
  &--space-blue-d5fd {
    border-color: $space-blue-d5fd;
  }
  &--gray-c0 {
    border-color: $gray-c0 !important;
  }
  &--gray-9c {
    border-color: $gray-9c !important;
  }
  &--purple-D3E1 {
    border-color: $purple-D3E1;
  }
  &--gray-f0 {
    border-color: $gray-f0 !important;
  }
  &--gray-f5fa {
    border-color: $gray-f5fa;
  }
  &--red-f037 {
    border-color: $red-f037 !important;
  }
}

.hover-border {
  &--1 {
    &:hover {
      border: 1px solid;
    }
  }
  &--orange {
    &:hover {
      border-color: $orange;
    }
  }
}
