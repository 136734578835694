.btn {
  &:focus {
    box-shadow: none;
    outline: none;
  }
  &.btn--orange {
    background: $orange;
    color: $white;
    font-family: "sf_ui_textregular";
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      color: $white;
      background: $dark-orange-1;
      border-color: $dark-orange-1;
    }
    &-outline {
      background: transparent;
      color: $orange;
      border: 1px solid $orange;
    }
  }
  &.btn--red {
    background: $red-4;
    color: $white;
    font-family: "sf_ui_textregular";
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      color: $white;
      background: $red-3;
      border-color: $red-3;
    }
    &-outline {
      background: transparent;
      color: $orange;
      border: 1px solid $orange;
    }
  }
  &.btn--green {
    background: $green-699A31;
    color: $white;
    font-family: "sf_ui_textregular";
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      color: $white;
      background: $green;
      border-color: $green;
    }
    &-outline {
      background: transparent;
      color: $orange;
      border: 1px solid $orange;
    }
  }
  &.btn--white {
    background: $white;
    color: $black-2129;
    font-family: "sf_ui_textregular";
    border-radius: 8px;
    cursor: pointer;
  }
  &.btn-cancel {
    background: transparent;
    color: $dark-gray-2;
    border: 1px solid $gray-cc;
  }
  &.btn-light-gray {
    background: $gray-ecef;
    padding: 10px 15px;
    display: block;
    text-align: center;
    border-radius: 8px;
    color: $black;
    font-family: "sf_ui_textmedium";
    &-outline {
      background: transparent;

      border: 1px solid $gray-ecef;
    }
  }
  &.btn--blue {
    background: #1f64ff;
    border-color: #1f64ff;
    color: #fff;
    font-family: "sf_ui_textmedium";
  }
  &.btn--blue-2aa1 {
    padding: 5px 20px;
    color: #fff;

    background: #457afe;
    border: 1px solid #457afe;
    font-size: 15px;
    width: auto;
    display: block;
    font-family: "sf_ui_textmedium";
  }
  &.btn--join-blue {
    padding: 5px 20px;
    color: $white;
    border-radius: 8px;
    background: $blue-2aa1;
    border: 1px solid $blue-2aa1;
    font-size: 15px;
    width: auto;
    display: block;
  }
  &.btn--back-blue {
    padding: 5px 20px;
    color: $white;
    border-radius: 8px;
    background: $gray-d5fd;
    border: 1px solid $blue-8bcc;
    font-size: 15px;
    width: auto;
    display: block;
  }
}
