.text {
  &--black {
    color: $black !important;
  }
  &--black-1a1d {
    color: $black-1a1d !important;
  }
  &--orange {
    color: $orange !important;
  }
  &--orange-f895 {
    color: $orange-f895 !important;
  }
  &--white {
    color: $white !important;
  }
  &--yellow {
    color: $yellow !important;
  }
  &--rating-star-yellow {
    color: $rating-star-yellow !important;
  }
  &--pdf-red {
    color: $pdf-red;
  }
  &--navy-blue {
    color: $navy-blue;
  }
  &--blue {
    color: $blue !important;
  }
  &--blue-1d3f {
    color: $blue-1d3f !important;
  }
  &--blue-23fe {
    color: $blue-23fe !important;
  }
  &--blue-2 {
    color: $blue-2 !important;
  }
  &--red {
    color: $red;
  }
  &--red-f037 {
    color: $red-f037;
  }
  &--red-D3381D {
    color: $red-D3381D;
  }
  &--red-fc4a {
    color: $red-fc4a;
  }
  &--green {
    color: $green;
  }
  &--green-2 {
    color: $green-2;
  }
  &--green-0054 {
    color: $green-0054;
  }
  &--green-0732 {
    color: $green-0732;
  }
  &--green-3 {
    color: $green-3;
  }
  &--dark-teal-green {
    color: $dark-teal-green;
  }
  &--black-191b {
    color: $black-191b;
  }
  &--black-40 {
    color: $black-40;
  }
  &--black-2129 {
    color: $black-2129;
  }
  &--black-33 {
    color: $black-33;
  }
  &--black-41d4 {
    color: $black-41d4;
  }
  &--black-6 {
    color: $black-6 !important;
  }

  &--purple-6f99 {
    color: $purple-6f99 !important;
  }
  &--purple-6e93 {
    color: $purple-6e93;
  }
  &--purple-6b9a {
    color: $purple-6b9a;
  }
  &--purple-3f4e {
    color: $purple-3f4e !important;
  }
  &--purple-4c66 {
    color: $purple-4c66 !important;
  }
  &--blue-1968 {
    color: $blue-1968 !important;
  }
  &--gray-88 {
    color: $gray-88 !important;
  }
  &--gray-cc {
    color: $gray-cc !important;
  }
  &--gray-c0 {
    color: $gray-c0 !important;
  }
  &--gray-c4 {
    color: $gray-c4 !important;
  }
  &--gray-64 {
    color: $gray-64 !important;
  }

  &--gray-icon {
    color: $gray-icon;
  }
  &--gray-ec {
    color: $gray-ec;
  }
  &--gray-7e {
    color: $gray-7e;
  }
  &--gray-5d {
    color: $gray-5d;
  }
  &--gray-dd {
    color: $gray-dd !important;
  }
  &--gray-4d {
    color: $gray-4d;
  }
  &--gray-d9 {
    color: $gray-d9;
  }
  &--gray-a6b7 {
    color: $gray-a6b7;
  }
  &--gray-9db7 {
    color: $gray-9db7;
  }
  &--gray-8a90 {
    color: $gray-8a90;
  }
  &--gray-4757 {
    color: $gray-4757;
  }
  &--gray-97 {
    color: $gray-97;
  }
  &--gray-da {
    color: $gray-da;
  }
  &--gray-d8 {
    color: $gray-d8;
  }
  &--gray-66 {
    color: $gray-66;
  }
  &--gray-a4 {
    color: $gray-a4;
  }
  &--gray-6971 {
    color: $gray-6971;
  }
  &--gray-3b {
    color: $gray-3b;
  }
  &--gray-9a {
    color: $gray-9a;
  }
  &--dark-gray-1 {
    color: $dark-gray-1 !important;
  }
  &--dark-gray-4c65 {
    color: $dark-gray-4c65;
  }
  &--light-gray-ee {
    color: $light-gray-ee;
  }
  &--dark-gray-2 {
    color: $dark-gray-2;
  }
  &--purple-3665 {
    color: $purple-3665 !important;
  }
  &--chat-gray-bg {
    color: $chat-gray-bg;
  }
  &--dark-gray-3 {
    color: $dark-gray-3;
  }
  &--gray-7b8a {
    color: $gray-7b8a;
  }
  &--gray-5b7d {
    color: $gray-5b7d;
  }
  &--gray-7c87 {
    color: $gray-7c87;
  }

  &--gray-59 {
    color: $gray-59;
  }
  &--gray-9c {
    color: $gray-9c;
  }
  &--blue-32cc {
    color: $blue-32cc !important;
  }
  &--blue-54d4 {
    color: $blue-54d4 !important;
  }

  &--purple-80a3 {
    color: $purple-80a3 !important;
  }
  &--gray-778f {
    color: $gray-778f;
  }
  &--gray-80 {
    color: $gray-80;
  }
  &--gray-icon-aac8 {
    color: $gray-icon-aac8;
  }
  &--gray-3b6e {
    color: $gray-3b6e !important;
  }
  &--inner-sidebar-gray-icon {
    color: $inner-sidebar-gray-icon;
  }
  &--blue-2aa1 {
    color: $blue-2aa1;
  }
  &--hover-white {
    &:hover {
      color: $white;
    }
  }
  &--hover-orange {
    &:hover {
      color: $orange;
    }
  }
}

.focus {
  &--text--black-40:focus {
    color: $black-40 !important;
  }
}

.hover-text {
  &--none {
    &:hover {
      color: transparent;
    }
  }
  &--orange {
    &:hover {
      color: $orange !important;
    }
  }
  &--gray-7e {
    &:hover {
      color: $gray-7e;
    }
  }
  &--gray-9c {
    &:hover {
      color: $gray-9c;
    }
  }

  &--white {
    &:hover {
      color: $white !important;
    }
  }
  &--red-f037 {
    &:hover {
      color: $red-f037 !important;
    }
  }
}
