.svg {
  &--22 {
    width: 22px;
  }
  &--20 {
    svg {
      width: 20px;
      height: 20px;
      }
  }
  &--23 {
    svg {
      width: 23px;
      height: 23px;
      }
  }
  &--24 {
    svg {
      width: 24px;
      height: 24px;
      }
  }
  &--25 {
    svg {
    width: 25px;
    height: 25px;
    }
  }
  &--26 {
    svg {
    width: 26px;
    height: 26px;
    }
  }
  &--28 {
    svg {
    width: 28px;
    height: 28px;
    }
  }
  &--stroke-white {
    svg {
      path {
        stroke: $white;
      }
    }
  }

  &--xs {
    svg {
      width: 0.8rem;
      height: 0.8rem;
    }
  }
  &--xs-2 {
    svg {
      width: 1rem;
      height: 1rem;
    }
  }
  &--sm {
    svg {
      width: 1.2rem;
      height: 1.2rem;
    }
  }
  &--sm-2 {
    svg {
      width: 22px;
      height: 22px;
    }
  }
  &--md {
    svg {
      width: 1.8rem;
      height: 1.8rem;
    }
  }
  &--lg {
    svg {
      width: 2.1rem;
      height: 2.1rem;
    }
  }
  &--lg-2 {
    svg {
      width: 40px;
      height: 40px;
    }
  }
  &--xl {
    svg {
      width: 3.5rem;
      height: 3.5rem;
    }
  }
  --lg {
    width: 33.59;
    height: 33.59;
  }
}
