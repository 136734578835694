.m {
  &--0 {
    margin: 0;
  }
  &t--0 {
    margin-top: 0;
  }
  &r--0 {
    margin-right: 0;
  }
  &b--0 {
    margin-bottom: 0 !important;
  }
  &l--auto {
    margin-left: auto;
  }
  &l--0 {
    margin-left: 0;
  }
  &--3 {
    margin: 3px;
  }
  &t--3 {
    margin-top: 3px;
  }
  &r--3 {
    margin-right: 3px;
  }
  &b--3 {
    margin-bottom: 3px !important;
  }
  &l--3 {
    margin-left: 5px;
  }
  &--5 {
    margin: 5px;
  }
  &t--5 {
    margin-top: 5px !important;
  }
  &r--5 {
    margin-right: 5px;
  }
  &b--5 {
    margin-bottom: 5px !important;
  }
  &l--5 {
    margin-left: 5px !important;
  }
  &--6 {
    margin: 6px;
  }
  &t--6 {
    margin-top: 6px;
  }
  &r--6 {
    margin-right: 6px;
  }
  &b--6 {
    margin-bottom: 6px !important;
  }
  &l--6 {
    margin-left: 6px;
  }

  &--8 {
    margin: 8px;
  }
  &t--8 {
    margin-top: 8px;
  }
  &r--8 {
    margin-right: 8px;
  }
  &b--8 {
    margin-bottom: 8px;
  }
  &l--8 {
    margin-left: 8px;
  }
  &--10 {
    margin: 10px;
  }
  &t--10 {
    margin-top: 10px !important;
  }
  &r--10 {
    margin-right: 10px;
  }
  &b--10 {
    margin-bottom: 10px;
  }
  &l--10 {
    margin-left: 10px;
  }
  &--12 {
    margin: 12px;
  }
  &t--12 {
    margin-top: 12px;
  }
  &r--12 {
    margin-right: 12px;
  }
  &b--12 {
    margin-bottom: 12px;
  }
  &l--12 {
    margin-left: 12px;
  }
  &--14 {
    margin: 14px;
  }
  &t--14 {
    margin-top: 14px;
  }
  &r--14 {
    margin-right: 14px;
  }
  &b--14 {
    margin-bottom: 14px;
  }
  &l--14 {
    margin-left: 14px;
  }

  &--15 {
    margin: 15px;
  }
  &t--15 {
    margin-top: 15px !important;
  }
  &r--15 {
    margin-right: 15px;
  }
  &b--15 {
    margin-bottom: 15px;
  }
  &l--15 {
    margin-left: 15px;
  }
  &--16 {
    margin: 16px;
  }
  &t--16 {
    margin-top: 16px;
  }
  &r--16 {
    margin-right: 16px;
  }
  &b--16 {
    margin-bottom: 16px;
  }
  &l--16 {
    margin-left: 16px;
  }
  &--18 {
    margin: 18px;
  }
  &t--18 {
    margin-top: 18px;
  }
  &r--18 {
    margin-right: 18px;
  }
  &b--18 {
    margin-bottom: 18px;
  }
  &l--18 {
    margin-left: 18px;
  }
  &--20 {
    margin: 20px;
  }
  &t--20 {
    margin-top: 20px;
  }
  &r--20 {
    margin-right: 20px;
  }
  &b--20 {
    margin-bottom: 20px !important;
  }
  &l--20 {
    margin-left: 20px;
  }

  &--24 {
    margin: 24px;
  }
  &t--24 {
    margin-top: 24px;
  }
  &r--24 {
    margin-right: 24px;
  }
  &b--24 {
    margin-bottom: 24px;
  }
  &l--24 {
    margin-left: 24px;
  }
  &--25 {
    margin: 25px;
  }
  &t--25 {
    margin-top: 25px;
  }
  &r--25 {
    margin-right: 25px;
  }
  &b--25 {
    margin-bottom: 25px !important;
  }
  &l--25 {
    margin-left: 25px;
  }
  &--30 {
    margin: 30px;
  }
  &t--30 {
    margin-top: 30px;
  }
  &b--30 {
    margin-bottom: 30px;
  }
  &l--30 {
    margin-left: 30px;
  }
  &r--30 {
    margin-right: 30px;
  }
  &--32 {
    margin: 32px;
  }
  &t--32 {
    margin-top: 32px;
  }
  &b--32 {
    margin-bottom: 32px;
  }
  &l--32 {
    margin-left: 32px;
  }
  &r--32 {
    margin-right: 32px;
  }
  &--35 {
    margin: 35px;
  }
  &t--35 {
    margin-top: 35px;
  }
  &b--35 {
    margin-bottom: 35px;
  }
  &l--35 {
    margin-left: 35px;
  }
  &r--35 {
    margin-right: 35px;
  }
  &--40 {
    margin: 40px;
  }
  &t--40 {
    margin-top: 40px;
  }
  &b--40 {
    margin-bottom: 40px !important;
  }
  &l--40 {
    margin-left: 40px;
  }
  &r--40 {
    margin-right: 40px;
  }
  &--48 {
    margin: 48px;
  }
  &t--48 {
    margin-top: 48px;
  }
  &b--48 {
    margin-bottom: 48px;
  }
  &l--48 {
    margin-left: 48px;
  }
  &r--48 {
    margin-right: 48px;
  }
  &--50 {
    margin: 50px;
  }
  &t--50 {
    margin-top: 50px;
  }
  &b--50 {
    margin-bottom: 50px;
  }
  &l--50 {
    margin-left: 50px;
  }
  &r--50 {
    margin-right: 50px;
  }
  &--53 {
    margin: 53px;
  }
  &t--53 {
    margin-top: 53px;
  }
  &b--53 {
    margin-bottom: 53px;
  }
  &l--53 {
    margin-left: 53px;
  }
  &r--53 {
    margin-right: 53px;
  }
  &--60 {
    margin: 60px;
  }
  &t--60 {
    margin-top: 60px;
  }
  &b--60 {
    margin-bottom: 60px;
  }
  &l--60 {
    margin-left: 60px;
  }
  &r--60 {
    margin-right: 60px;
  }
  &--65 {
    margin: 65px;
  }
  &t--65 {
    margin-top: 65px;
  }
  &b--65 {
    margin-bottom: 65px;
  }
  &l--65 {
    margin-left: 65px;
  }
  &r--65 {
    margin-right: 65px;
  }
  &--70 {
    margin: 70px;
  }
  &t--70 {
    margin-top: 70px;
  }
  &b--70 {
    margin-bottom: 70px;
  }
  &l--70 {
    margin-left: 70px;
  }
  &r--70 {
    margin-right: 70px;
  }
  &t--100 {
    margin-top: 100px;
  }
  &l--80 {
    margin-left: 80px;
  }
  &l---10 {
    margin-left: -10px;
  }
  &t---100 {
    margin-top: -100px;
  }
  @media (max-width: 767px) {
    &--sm-auto {
      margin: auto;
    }
    &--sm-0 {
      margin: 0px;
    }
    &t--sm-15 {
      margin-top: 15px;
    }
    &t--sm-30 {
      margin-top: 30px;
    }
    &t--sm-40 {
      margin-top: 40px;
    }
    &b--sm-10 {
      margin-bottom: 10px;
    }
    &b--sm-15 {
      margin-bottom: 15px !important;
    }
    &b--sm-30 {
      margin-bottom: 30px;
    }
    &b--sm-35 {
      margin-bottom: 35px;
    }
    &b--sm-40 {
      margin-bottom: 40px;
    }
  }
}
