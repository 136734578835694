* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

#root {
  height: 100%;
}
html {
  height: 100%;
}

body {
  // font-family: $sf-ui;
  background: #eee;
  height: 100%;
  font-size: 14px;
  -webkit-animation: fadein 3s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 3s;
  /* Firefox < 16 */
  -ms-animation: fadein 3s;
  /* Internet Explorer */
  -o-animation: fadein 3s;
  /* Opera < 12.1 */
  animation: fadein 3s;
  // overflow: hidden;
  -webkit-font-smoothing: antialiased;
  font-family: "sf_ui_textregular";
  font-size: 14px;
  overflow-x: hidden;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #fff;
  border: 1px solid #c4c4c4;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #c4c4c4;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #cccccc;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.c {
  &--pointer {
    cursor: pointer;
  }
  &--text {
    cursor: text;
  }
}

.p--relative {
  position: relative;
}

.object-fit {
  &--cover {
    object-fit: cover;
  }
  &--contain {
    object-fit: contain;
  }
}

.white-space {
  &-no-wrap {
    white-space: nowrap;
  }
  &-wrap {
    white-space: pre-wrap;
  }
}

.opacity {
  &--0 {
    opacity: 0;
  }
}
.box-shadow {
  &-none {
    box-shadow: none !important;
  }
  &-1 {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  }
  &-2 {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08), 0 2px 2px rgba(0, 0, 0, 0.12), 0 4px 4px rgba(0, 0, 0, 0.16), 0 8px 8px rgba(0, 0, 0, 0.2);
  }
  &-2 {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08), 0 2px 2px rgba(0, 0, 0, 0.12), 0 4px 4px rgba(0, 0, 0, 0.16), 0 8px 8px rgba(0, 0, 0, 0.2);
  }
  &-3 {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  }
}

.loader-page {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.after-none::after {
  display: none !important;
}
.focus {
  &--outline-none {
    outline: none !important;
  }
}

// list style scss start here
.list-style {
  &-none {
    list-style: none;
  }
  &-auto {
    list-style: auto;
  }
}
// list style scss end here

.text {
  &--underline {
    text-decoration: underline;
  }
  &--decoration-none {
    text-decoration: none !important;
  }
}

// check box start here
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  text-align: right;
  margin: 0;

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    border-radius: 100px;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border: 1px solid #adb5bd;
    width: 50px;
    height: 24px;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 2px;
    background-color: #adb5bd;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 100px;
  }
  input:checked + .slider:before {
    left: 5px;
    background-color: #ffffff;
  }

  input:checked + .slider {
    background-color: #f17c2b;
    border: 1px solid #f17c2b;
  }

  input:focus + .slider {
    background-color: #f17c2b;
    border: 1px solid #f17c2b;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(24px);
    -ms-transform: translateX(24px);
    transform: translateX(24px);
  }
}
input[type="checkbox"] + label {
  display: block;
  margin: 0.2em;
  cursor: pointer;
  padding: 0.2em;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + label:before {
  content: "\2714";
  border-color: #a8a8a8;
  border-radius: 5px;
  display: inline-block;
  width: 17px;
  height: 17px;
  padding-bottom: 1px;
  margin-right: 5px;
  vertical-align: middle;
  color: transparent;
  transition: 0.2s;
  font-size: 11px;
  padding-left: 0px;
  border: 1px solid #a8a8a8;
  font-family: "sf_ui_textmedium";
}

input[type="checkbox"] + label:active:before {
  transform: scale(0);
}

input[type="checkbox"]:checked + label:before {
  color: #ff4f4f;

  font-family: "sf_ui_textmedium";
}

input[type="checkbox"]:disabled + label:before {
  transform: scale(1);
  border-color: #aaa;
}

input[type="checkbox"]:checked:disabled + label:before {
  transform: scale(1);
  background-color: #bfb;
  border-color: #bfb;
}

// check box end here

input[type="radio"] {
  appearance: none;
  display: inline-block;
  width: 25px;
  height: 25px;
  padding: 6px;
  background-clip: content-box;
  border: 2px solid #bbb;
  background-color: #e7e6e7;
  border-radius: 50%;
  outline: none;
  vertical-align: middle;
  float: right;
}
input[type="radio"]:checked {
  background-color: #f17c2b;
}

.srv-validation-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.flex-1 {
  flex: 1;
}

.z-index {
  &--1 {
    z-index: 1;
  }
  &--9 {
    z-index: 9;
  }
}

// .opacity-css start here
.opacity {
  &--07 {
    opacity: 0.7;
  }
  &--1 {
    opacity: 1;
  }
}

// overflow css start here
.overflow {
  &--y-auto {
    overflow-y: auto;
  }
  &--x-auto {
    overflow-x: auto;
  }
  &--unset {
    overflow: unset !important;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.css-19bb58m {
  input {
    grid-area: 1 / 3 / auto / auto !important;
  }
}

@media (max-width: 767px) {
  .text--sm-center {
    text-align: center !important;
  }
  .flex--sm-column {
    flex-direction: column;
  }
  .justify-content--sm-center {
    justify-content: center;
  }
  .align-items--sm-center {
    align-items: center !important;
  }
}
.terms-svg {
  &::before {
    @media screen and (max-width: 767px) {
      display: none !important;
    }
  }
}
.sign-left-part-vector-img {
  .forgot-vector {
    width: 100%;
    height: 100%;
    max-height: 400px;
    display: block;
    margin: 0 auto;
  }
}
