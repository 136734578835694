.bg {
  &--transparent {
    background: transparent;
  }
  &--orange {
    background: $orange !important;
  }
  &--orange-EF9F58 {
    background: $orange-EF9F58 !important;
  }
  &--rating-star-yellow {
    background: $rating-star-yellow;
  }
  &--fade-orange {
    background: $fade-orange;
  }
  &--white {
    background: $white;
  }
  &--light-navy-blue {
    background: $light-navy-blue;
  }
  &--gray-fa {
    background: $gray-fa;
  }
  &--light-gray-ee {
    background: $light-gray-ee !important;
  }
  &--gray-f1 {
    background: $gray-f1 !important;
  }
  &--gray-d2ec {
    background: $gray-d2ec;
  }
  &--gray-d8 {
    background: $gray-d8;
  }
  &--dark-gray-4 {
    background: $dark-gray-4;
  }
  &--gray-dbf0 {
    background: $gray-dbf0;
  }
  &--gray-3b6e {
    background: $gray-3b6e;
  }
  &--gray-e6 {
    background: $gray-e6;
  }
  &--gray-c4 {
    background: $gray-c4;
  }
  &--gray-da {
    background: $gray-da;
  }
  &--gray-cf {
    background: $gray-cf;
  }
  &--gray-f5fa {
    background: $gray-f5fa;
  }
  &--gray-d9 {
    background: $gray-d9 !important;
  }
  &--gray-ec {
    background: $gray-ec;
  }
  &--gray-e9ef {
    background: $gray-e9ef;
  }
  &--gray-icon {
    background: $gray-icon !important;
  }
  &--gray-e7ef {
    background: $gray-e7ef !important;
  }
  &--gray-f0 {
    background: $gray-f0 !important;
  }
  &--gray-eff3 {
    background: $gray-eff3 !important;
  }
  &--gray-bebebe {
    background: $gray-bebebe !important;
  }
  &--blue {
    background: $blue;
  }
  &--green {
    background: $green;
  }
  &--green-f4dc {
    background: $green-f4dc;
  }
  &--green-4525 {
    background: $green-4525;
  }
  &--green-2 {
    background: $green-2;
  }
  &--light-green {
    background: $light-green;
  }
  &--sky-blue {
    background: $sky-blue;
  }
  &--light-purple {
    background: $light-purple;
  }
  &--purple {
    background: $purple;
  }
  &--purple-bcda {
    background: $purple-bcda;
  }
  &--light-red {
    background: $light-red;
  }
  &--red {
    background: $red;
  }
  &--red-feec {
    background: $red-feec;
  }
  &--red-2 {
    background: $red-2;
  }
  &--red-3 {
    background: $red-3;
  }
  &--red-f037 {
    background: $red-f037;
  }
  &--red-fe29 {
    background: $red-fe29;
  }
  &--red-ec {
    background: $red-ec !important;
  }
  &--space-blue {
    background: $space-blue;
  }
  &--blue-45fe {
    background: $blue-45fe;
  }
  &--space-blue-d5fd {
    background: $space-blue-d5fd;
  }
  &--space-blue-eefb {
    background: $space-blue-eefb;
  }
  &--space-blue-f1ec {
    background: $space-blue-f1ec;
  }
  &--dark-pink {
    background: $dark-pink;
  }

  &--pink-ffef {
    background: $pink-ffef;
  }
  &--pink-ffc5 {
    background: $pink-ffc5;
  }
  &--skin-red {
    background: $skin-ffe0;
  }
  &--yellow-e8d5 {
    background: $yellow-e8d5;
  }
  &--light-yellow {
    background: $light-yellow;
  }
  &--chat-gray-bg {
    background: $chat-gray-bg;
  }
  &--light-teal-green {
    background: $light-teal-green;
  }
  &--green-c4cf {
    background: $green-c4cf;
  }
  &--black-6 {
    background: $black-6;
  }
  &--black-40 {
    background: $black-40;
  }
  &--yellow {
    background: $yellow;
  }
  &--yellow-ff79 {
    background: $yellow-ff79;
  }
  &--light-blue {
    background: $light-blue;
  }
  &--light-purple {
    background: $light-purple;
  }
  &--purple-6f99 {
    background: $purple-6f99;
  }
  &--purple-6e93 {
    background: $purple-6e93;
  }
  &--blue-2aa1 {
    background: $blue-2aa1;
  }
}

.hover-bg {
  &--red-f037 {
    &:hover {
      background: $red-f037;
    }
  }
  &--gray-ec {
    &:hover {
      background: $gray-ec;
    }
  }
  &--gray-f4 {
    &:hover {
      background: $gray-f4;
    }
  }
  &--orange {
    &:hover {
      background-color: $orange !important;
    }
  }
  &--purple-6f99 {
    &:hover {
      background: $purple-6f99;
    }
  }
  &--blue {
    &:hover {
      background: $blue;
    }
  }
}
