/* font start  here */
@font-face {
  font-family: "sf_ui_displayblack";
  src: url("../../../public/assets/fonts/sf-ui-display-black-58646a6b80d5a-webfont.woff2") format("woff2"), url("../../../public/assets/fonts/sf-ui-display-black-58646a6b80d5a-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "sf_ui_displaybold";
  src: url("../../../public/assets/fonts/sf-ui-display-bold-58646a511e3d9-webfont.woff2") format("woff2"), url("../../../public/assets/fonts/sf-ui-display-bold-58646a511e3d9-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "sf_ui_displaylight";
  src: url("../../../public/assets/fonts/sf-ui-display-light-58646b33e0551-webfont.woff2") format("woff2"), url("../../../public/assets/fonts/sf-ui-display-light-58646b33e0551-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "sf_ui_displaymedium";
  src: url("../../../public/assets/fonts/sf-ui-display-medium-58646be638f96-webfont.woff2") format("woff2"), url("../../../public/assets/fonts/sf-ui-display-medium-58646be638f96-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "sf_ui_displaysemibold";
  src: url("../../../public/assets/fonts/sf-ui-display-semibold-58646eddcae92-webfont.woff2") format("woff2"), url("../../../public/assets/fonts/sf-ui-display-semibold-58646eddcae92-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "sf_ui_textbold";
  src: url("../../../public/assets/fonts/sfuitext-bold-webfont.woff2") format("woff2"), url("../../../public/assets/fonts/sfuitext-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "sf_ui_textlight";
  src: url("../../../public/assets/fonts/sfuitext-light-webfont.woff2") format("woff2"), url("../../../public/assets/fonts/sfuitext-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "sf_ui_textmedium";
  src: url("../../../public/assets/fonts/sfuitext-medium-webfont.woff2") format("woff2"), url("../../../public/assets/fonts/sfuitext-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "sf_ui_textregular";
  src: url("../../../public/assets/fonts/sfuitext-regular-webfont.woff2") format("woff2"), url("../../../public/assets/fonts/sfuitext-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "sf_ui_textsemibold";
  src: url("../../../public/assets/fonts/sfuitext-semibold-webfont.woff2") format("woff2"), url("../../../public/assets/fonts/sfuitext-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.sf {
  &--d-light {
    font-family: "sf_ui_displaylight" !important;
  }

  &--d-medium {
    font-family: "sf_ui_displaymedium" !important;
  }
  &--d-semibold {
    font-family: "sf_ui_displaysemibold" !important;
  }
  &--d-bold {
    font-family: "sf_ui_displaybold" !important;
  }
  &--t-light {
    font-family: "sf_ui_textlight" !important;
  }
  &--t-bold {
    font-family: "sf_ui_textbold" !important;
  }
  &--t-medium {
    font-family: "sf_ui_textmedium" !important;
  }
  &--t-regular {
    font-family: "sf_ui_textregular" !important;
  }
  &--t-semibold {
    font-family: "sf_ui_textsemibold " !important;
  }
}
.helvetica {
  font-family: "Helvetica Neue", sans-serif;
}
.dm-sans {
  font-family: $dm-sans;
}
.poppins {
  font-family: $poppins;
}
.inter {
  font-family: $inter;
}
.roboto {
  font-family: "Roboto", sans-serif;
}
