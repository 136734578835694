.gap {
  &--3 {
    gap: 3px;
    grid-gap: 3px;
    -webkit-gap: 3px;
  }
  &--5 {
    gap: 5px;
    grid-gap: 5px;
    -webkit-gap: 5px;
  }
  &--6 {
    gap: 6px;
    grid-gap: 6px;
    -webkit-gap: 6px;
  }
  &--7 {
    gap: 7px;
    grid-gap: 7px;
    -webkit-gap: 7px;
  }
  &--8 {
    gap: 8px;
    grid-gap: 8px;
    -webkit-gap: 8px;
  }
  &--10 {
    gap: 10px;
    grid-gap: 10px;
    -webkit-gap: 10px;
  }
  &--12 {
    gap: 12px;
    grid-gap: 12px;
    -webkit-gap: 12px;
  }
  &--13 {
    gap: 13px;
    grid-gap: 13px;
    -webkit-gap: 13px;
  }
  &--15 {
    gap: 15px;
    grid-gap: 15px;
    -webkit-gap: 15px;
  }
  &--16 {
    gap: 16px;
    grid-gap: 16px;
    -webkit-gap: 16px;
  }
  &--17 {
    gap: 17px;
    grid-gap: 17px;
    -webkit-gap: 17px;
  }
  &--20 {
    gap: 20px;
    grid-gap: 20px;
    -webkit-gap: 20px;
  }
  &--25 {
    gap: 25px;
    grid-gap: 25px;
    -webkit-gap: 25px;
  }
  &--30 {
    gap: 30px;
    grid-gap: 30px;
    -webkit-gap: 30px;
  }
  &--35 {
    gap: 35px;
    grid-gap: 35px;
    -webkit-gap: 35px;
  }
  &--40 {
    gap: 40px;
    grid-gap: 40px;
    -webkit-gap: 40px;
  }
  &--48 {
    gap: 48px;
    grid-gap: 48px;
    -webkit-gap: 48px;
  }
  &--55 {
    gap: 55px;
    grid-gap: 55px;
    -webkit-gap: 55px;
  }
  &--65 {
    gap: 65px;
    grid-gap: 65px;
    -webkit-gap: 65px;
  }
  &--130 {
    gap: 130px;
    grid-gap: 130px;
    -webkit-gap: 130px;
  }
  &--column-20 {
    column-gap: 20px;
  }
  &--column-75 {
    column-gap: 75px;
  }
  &--row-5 {
    row-gap: 5px;
  }
  &--row-15 {
    row-gap: 15px;
  }
  &--row-25 {
    row-gap: 25px;
  }
  &--row-30 {
    row-gap: 30px;
  }
  @media (max-width: 767px) {
    &--sm-10 {
      gap: 10px;
    }
    &--sm-15 {
      gap: 15px;
    }
    &--sm-25 {
      gap: 25px;
    }
  }
}
