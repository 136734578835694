.login-page {
  .input--group {
    .input {
      font-size: 14px;
      min-height: 38px;
      padding: 6px 15px;
      border: 1px solid #d2d2d2;
      border-radius: 8px;
      background-color: $white;
    }
  }
  @media (max-width: 767px) {
    .navbar-nav {
      background: $gray-f0;
      padding: 10px;
      border-radius: 10px;
      margin-top: 10px;
      .nav-item {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
