.d {
  &-grid {
    display: grid;
  }
}
.grid {
  &--column-3 {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 767px) {
    &--sm-column-1 {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
