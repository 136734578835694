.Meet-outer {
  .meet-banner-sec {
    padding: 125px 0 60px 0;
    @media (max-width: 767px) {
      padding: 30px 0;
    }
  }
  // &::before {
  //   content: "";
  //   width: 150px;
  //   height: 150px;
  //   position: absolute;
  //   right: -100px;
  //   top: 240px;
  //   background-size: 100% 100%;
  //   background: url(../../../public/assets/images/Rectangle42.png) no-repeat;

  //   @media (max-width: 767px) {
  //     display: none;
  //   }
  //   @media (min-width: 1600px) {
  //     bottom: 145px;
  //   }
  // }
  // &::after {
  //   content: "";
  //   width: 140px;
  //   height: 120px;
  //   position: absolute;

  //   right: -35px;
  //   top: 200px;
  //   background-size: 100% 100%;
  //   background: url(../../../public/assets/images/Rectangle.png) left top
  //     no-repeat;

  //   @media (max-width: 767px) {
  //     display: none;
  //   }
  //   @media (min-width: 1600px) {
  //     bottom: 385px;
  //   }
  // }
  .why-use-nxa-sec {
    padding: 60px 0;
    @media (max-width: 767px) {
      padding: 30px 0;
    }
  }
}
