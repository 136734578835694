.font {
  &--8 {
    font-size: 8px;
  }
  &--9 {
    font-size: 9px;
  }
  &--10 {
    font-size: 10px;
  }
  &--11 {
    font-size: 11px;
  }
  &--12 {
    font-size: 12px !important;
  }
  &--13 {
    font-size: 13px;
  }
  &--14 {
    font-size: 14px !important;
  }
  &--15 {
    font-size: 15px !important;
  }
  &--16 {
    font-size: 16px;
  }
  &--17 {
    font-size: 17px;
  }
  &--18 {
    font-size: 18px;
  }
  &--19 {
    font-size: 19px;
  }
  &--20 {
    font-size: 20px;
  }
  &--21 {
    font-size: 21px;
  }
  &--22 {
    font-size: 22px;
  }
  &--24 {
    font-size: 24px;
  }
  &--25 {
    font-size: 25px;
  }
  &--26 {
    font-size: 26px;
  }
  &--28 {
    font-size: 28px;
  }
  &--30 {
    font-size: 30px;
  }
  &--31 {
    font-size: 31px;
  }
  &--33 {
    font-size: 33px;
  }
  &--36 {
    font-size: 36px;
  }
  &--42 {
    font-size: 42px;
  }
  &--45 {
    font-size: 45px;
  }
  &--46 {
    font-size: 46px;
  }
  &--48 {
    font-size: 48px;
  }
  @media (max-width: 767px) {
    &--sm-15 {
      font-size: 15px;
    }
    &--sm-16 {
      font-size: 16px;
    }
    &--sm-20 {
      font-size: 20px;
    }
    &--sm-30 {
      font-size: 30px;
    }
  }
}
