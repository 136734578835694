.privacy-content {
  .table {
    &.privacy-table {
      border-collapse: unset;
      border-spacing: unset;
      border: none;
      tr {
        background-color: transparent;
        border-radius: 0px;
        box-shadow: none;
        &:hover {
          box-shadow: none;
        }
      }
      thead {
        background-color: transparent;
        tr {
          th {
            background-color: transparent;
            border-radius: 0px;
            border-bottom: 1px solid #dee2e6 !important;
            &:not(:last-child) {
              border-right: 0px;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            background-color: transparent;
            border-radius: 0px;
            &:not(:last-child) {
              border-right: 0px;
            }
          }
        }
      }
    }
  }
}
.list-style-none {
  list-style-type: none;
}
