.founding-team {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 191px;
    height: 192px;
    left: -225px;
    bottom: 340px;
    background: url(../../../public/assets/images/about_page/shape.png) left top
      no-repeat;
    background-size: 100% 100%;
    @media (max-width: 767px) {
      display: none;
    }
  }
}
.about-box {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}
