.subscriptions-plan-modal {
  &-header {
    background-color: $gray-f9f;
  }

  &-content {
    max-width: 80%;
    @media screen and (max-width: 1024px) {
      max-width: 95%;
    }
  }
  &-body {
    .subscriptions-plan {
      &-section {
        max-height: calc(100vh - 218px);
        min-height: calc(100vh - 218px);
        overflow: auto;
      }
      &-header{
        width: fit-content;
        min-width: 100%;
      }
      &-footer {
        // position: sticky;
        // left: 0;
        // bottom: 0;
        // background-color: $white;
        width: fit-content;
        min-width: 100%;
      }
      &-container {
        min-height: calc(100vh - 410px);
        width: fit-content;
        min-width: 100%;
        .subscription-left-content {
          .plan-title {
            &::before {
              content: "-";
              font-weight: 400;
              font-size: 36px;
              left: 0px;
              position: absolute;
              top: -14px;
            }
          }
        }
      }
    }
  }
}
