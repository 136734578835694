.p {
  &--0 {
    padding: 0;
  }
  &t--0 {
    padding-top: 0;
  }
  &r--0 {
    padding-right: 0;
  }
  &b--0 {
    padding-bottom: 0;
  }
  &l--0 {
    padding-left: 0;
  }

  &--1 {
    padding: 1px;
  }
  &t--1 {
    padding-top: 1px;
  }
  &r--1 {
    padding-right: 1px;
  }
  &b--1 {
    padding-bottom: 1px;
  }
  &l--1 {
    padding-left: 1px;
  }
  &--2 {
    padding: 2px;
  }
  &t--2 {
    padding-top: 2px;
  }
  &r--2 {
    padding-right: 2px;
  }
  &b--2 {
    padding-bottom: 2px;
  }
  &l--2 {
    padding-left: 2px;
  }

  &--3 {
    padding: 3px !important;
  }
  &t--3 {
    padding-top: 3px;
  }
  &r--3 {
    padding-right: 3px !important;
  }
  &b--3 {
    padding-bottom: 3px;
  }
  &l--3 {
    padding-left: 3px;
  }
  &--4 {
    padding: 4px !important;
  }
  &t--4 {
    padding-top: 4px !important;
  }
  &r--4 {
    padding-right: 4px !important;
  }
  &b--4 {
    padding-bottom: 4px !important;
  }
  &l--4 {
    padding-left: 4px !important;
  }
  &--5 {
    padding: 5px !important;
  }
  &t--5 {
    padding-top: 5px !important;
  }
  &r--5 {
    padding-right: 5px;
  }
  &b--5 {
    padding-bottom: 5px;
  }
  &l--5 {
    padding-left: 5px;
  }
  &--6 {
    padding: 6px;
  }
  &t--6 {
    padding-top: 6px;
  }
  &r--6 {
    padding-right: 6px;
  }
  &b--6 {
    padding-bottom: 6px;
  }
  &l--6 {
    padding-left: 6px;
  }
  &--7 {
    padding: 7px;
  }
  &t--7 {
    padding-top: 7px;
  }
  &r--7 {
    padding-right: 7px;
  }
  &b--7 {
    padding-bottom: 7px;
  }
  &l--7 {
    padding-left: 7px;
  }
  &--8 {
    padding: 8px;
  }
  &t--8 {
    padding-top: 8px;
  }
  &r--8 {
    padding-right: 8px;
  }
  &b--8 {
    padding-bottom: 8px;
  }
  &l--8 {
    padding-left: 8px;
  }
  &--9 {
    padding: 9px;
  }
  &t--9 {
    padding-top: 9px;
  }
  &r--9 {
    padding-right: 9px;
  }
  &b--9 {
    padding-bottom: 9px;
  }
  &l--9 {
    padding-left: 9px;
  }

  &--10 {
    padding: 10px;
  }
  &t--10 {
    padding-top: 10px !important;
  }

  &b--10 {
    padding-bottom: 10px !important;
  }
  &r--10 {
    padding-right: 10px !important;
  }

  &l--10 {
    padding-left: 10px !important;
  }
  &--12 {
    padding: 12px;
  }
  &t--12 {
    padding-top: 12px;
  }

  &b--12 {
    padding-bottom: 12px;
  }
  &r--12 {
    padding-right: 12px;
  }

  &l--12 {
    padding-left: 12px;
  }
  &--14 {
    padding: 14px;
  }
  &t--14 {
    padding-top: 14px;
  }

  &b--14 {
    padding-bottom: 14px;
  }
  &r--14 {
    padding-right: 14px;
  }

  &l--14 {
    padding-left: 14px;
  }

  &--15 {
    padding: 15px;
  }
  &t--15 {
    padding-top: 15px !important;
  }
  &r--15 {
    padding-right: 15px;
  }
  &b--15 {
    padding-bottom: 15px !important;
  }
  &l--15 {
    padding-left: 15px;
  }
  &--16 {
    padding: 16px;
  }
  &t--16 {
    padding-top: 16px !important;
  }
  &r--16 {
    padding-right: 16px;
  }
  &b--16 {
    padding-bottom: 16px !important;
  }
  &l--16 {
    padding-left: 16px;
  }
  &--17 {
    padding: 17px;
  }
  &t--17 {
    padding-top: 17px !important;
  }
  &r--17 {
    padding-right: 17px;
  }
  &b--17 {
    padding-bottom: 17px !important;
  }
  &l--17 {
    padding-left: 17px;
  }
  &--18 {
    padding: 18px;
  }
  &t--18 {
    padding-top: 18px;
  }
  &r--18 {
    padding-right: 18px;
  }
  &b--18 {
    padding-bottom: 18px;
  }
  &l--18 {
    padding-left: 18px;
  }
  &--19 {
    padding: 19px;
  }
  &t--19 {
    padding-top: 19px;
  }
  &r--19 {
    padding-right: 19px;
  }
  &b--19 {
    padding-bottom: 19px;
  }
  &l--19 {
    padding-left: 19px;
  }
  &--20 {
    padding: 20px;
  }
  &t--20 {
    padding-top: 20px;
  }
  &r--20 {
    padding-right: 20px;
  }
  &b--20 {
    padding-bottom: 20px;
  }
  &l--20 {
    padding-left: 20px;
  }
  &--21 {
    padding: 21px;
  }
  &t--21 {
    padding-top: 21px;
  }
  &r--21 {
    padding-right: 21px;
  }
  &b--21 {
    padding-bottom: 21px;
  }
  &l--21 {
    padding-left: 21px;
  }
  &--23 {
    padding: 23px;
  }
  &t--23 {
    padding-top: 23px;
  }
  &r--23 {
    padding-right: 23px;
  }
  &b--23 {
    padding-bottom: 23px;
  }
  &l--23 {
    padding-left: 23px;
  }
  &--24 {
    padding: 24px;
  }
  &t--24 {
    padding-top: 24px;
  }
  &r--24 {
    padding-right: 24px;
  }
  &b--24 {
    padding-bottom: 24px;
  }
  &l--24 {
    padding-left: 24px;
  }
  &--25 {
    padding: 25px;
  }
  &t--25 {
    padding-top: 25px;
  }
  &r--25 {
    padding-right: 25px;
  }
  &b--25 {
    padding-bottom: 25px;
  }
  &l--25 {
    padding-left: 25px;
  }
  &--30 {
    padding: 30px;
  }
  &t--30 {
    padding-top: 30px;
  }
  &r--30 {
    padding-right: 30px !important;
  }
  &b--30 {
    padding-bottom: 30px;
  }
  &l--30 {
    padding-left: 30px !important;
  }
  &--32 {
    padding: 32px;
  }
  &t--32 {
    padding-top: 32px;
  }
  &r--32 {
    padding-right: 32px !important;
  }
  &b--32 {
    padding-bottom: 32px;
  }
  &l--32 {
    padding-left: 32px !important;
  }
  &--34 {
    padding: 34px;
  }
  &t--34 {
    padding-top: 34px;
  }
  &r--34 {
    padding-right: 34px !important;
  }
  &b--34 {
    padding-bottom: 34px;
  }
  &l--34 {
    padding-left: 34px !important;
  }
  &--38 {
    padding: 38px;
  }
  &t--38 {
    padding-top: 38px;
  }
  &r--38 {
    padding-right: 38px;
  }
  &b--38 {
    padding-bottom: 38px;
  }
  &l--38 {
    padding-left: 38px !important;
  }
  &--40 {
    padding: 40px;
  }
  &t--40 {
    padding-top: 40px;
  }
  &r--40 {
    padding-right: 40px;
  }
  &b--40 {
    padding-bottom: 40px;
  }
  &l--40 {
    padding-left: 40px !important;
  }
  &--45 {
    padding: 45px;
  }
  &t--45 {
    padding-top: 45px;
  }
  &r--45 {
    padding-right: 45px;
  }
  &b--45 {
    padding-bottom: 45px;
  }
  &l--45 {
    padding-left: 45px !important;
  }
  &--50 {
    padding: 50px;
  }
  &t--50 {
    padding-top: 50px;
  }
  &r--50 {
    padding-right: 50px;
  }
  &b--50 {
    padding-bottom: 50px;
  }
  &l--50 {
    padding-left: 50px;
  }
  &--57 {
    padding: 57px;
  }
  &t--57 {
    padding-top: 57px;
  }
  &r--57 {
    padding-right: 57px !important;
  }
  &b--57 {
    padding-bottom: 57px;
  }
  &l--57 {
    padding-left: 57px !important;
  }
  &--60 {
    padding: 60px;
  }
  &t--60 {
    padding-top: 60px;
  }
  &r--60 {
    padding-right: 60px !important;
  }
  &b--60 {
    padding-bottom: 60px;
  }
  &l--60 {
    padding-left: 60px !important;
  }
  &--65 {
    padding: 65px;
  }
  &t--65 {
    padding-top: 65px;
  }
  &r--65 {
    padding-right: 65px !important;
  }
  &b--65 {
    padding-bottom: 65px;
  }
  &l--65 {
    padding-left: 65px !important;
  }
  &t--80 {
    padding-top: 80px !important;
  }
  &r--90 {
    padding-right: 90px !important;
  }

  &t--100 {
    padding-top: 100px !important;
  }
  &r--100 {
    padding-right: 100px !important;
  }
  &l--195 {
    padding-left: 195px !important;
  }

  @media (max-width: 767px) {
    &--sm-0 {
      padding: 0px;
    }
    &r--sm-unset {
      padding-right: unset !important;
    }
    &t--sm-38 {
      padding-top: 38px;
    }
  }
}
