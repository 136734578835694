.fw {
  &--unset {
    font-weight: unset;
  }
  &--300 {
    font-weight: 300;
  }
  &--400 {
    font-weight: 400;
  }
  &--500 {
    font-weight: 500;
  }
  &--600 {
    font-weight: 600;
  }
  &--700 {
    font-weight: 700;
  }
  &--bold {
    font-weight: bold;
  }
}
