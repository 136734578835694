.homepage {
  header {
    .first-nav {
      .navbar-nav {
        .nav-item {
          .nav-link {
            transition: all 0.5s;
            &.active {
              border-radius: 16px 0;
              border: 2px solid #f17c2b !important;
            }
            &:hover {
              transition: all 0.5s;
            }
          }
        }
      }
    }
    .second-nav {
      .navbar-nav {
        .nav-item {
          .nav-link {
            &.active {
              border-radius: 16px 0;
              border: 2px solid #f17c2b !important;
            }
            &:hover {
              border-radius: 16px 0;
              color: $purple-3f4e;
            }
          }
          .dropdown-menu {
            .dropdown-item {
              border: 2px solid transparent;
              border-bottom: 1px solid $gray-f0fb;
              &:hover {
                border: 2px solid #f17c2b !important;
                background: transparent;
              }
              &.active {
                background-color: transparent;
                color: unset;
                border: 2px solid #f17c2b !important;
                background: transparent;
              }
            }
          }
        }
      }
    }
    // mobile section div css
    .navigation {
      .navbar-nav {
        .nav-item {
          border-radius: 5px;
          color: $gray-394e;
          // &.dropdown {
          //   &.show {
          //     a {
          //       color: $white;
          //     }
          //   }
          // }
          &:hover,
          :active,
          :focus {
            background: #f04037;
            color: $white !important;
            transition: all 0.5s;
            font-weight: 700;
            height: 100%;
          }
          &.active {
            .nav-link {
              background: #f04037;
              color: $white;
              transition: all 0.5s;
              font-weight: 700;
              height: 100%;
              border-radius: 7px;
            }
          }
          .nav-link {
            padding: 8px 10px;
            margin: 5px 0;
            color: #393f4e;
            font-size: 16px;
            font-weight: 700;
            border-radius: 7px;
          }
          .dropdown-menu {
            border: none;
            padding: 5px;
            margin-left: 0px;
            height: auto;
            .dropdown-item {
              margin-left: 0px;
              padding: 9px 12px;
              height: auto;
              &.active {
                color: #fff;
                text-decoration: none;
                background-color: #ea7414;
                border-radius: 7px;
              }
            }
          }
        }
      }
    }
  }
  .banner {
    &::before {
      content: "";
      width: 149px;
      height: 150px;
      position: absolute;
      background: url(../../../public/assets/images/home-page-img/banner-btm-rectangle.svg)
        no-repeat;
      left: 40px;
      bottom: 147px;
      background-size: 100% 100%;
      z-index: 1;
      @media (max-width: 767px) {
        display: none;
      }
      @media (min-width: 1600px) {
        bottom: 145px;
      }
    }
    &::after {
      content: "";
      position: absolute;
      width: 191px;
      height: 192px;
      left: -50px;
      bottom: 340px;
      background: url(../../../public/assets/images/home-page-img/home-banner-rectangle.svg)
        left top no-repeat;
      background-size: 100% 100%;
      @media (max-width: 767px) {
        display: none;
      }
      @media (min-width: 1600px) {
        bottom: 385px;
      }
    }
    .banner-inner {
      background: #eeeff3;
      border-radius: 0px 0px 0px 129px;
      padding: 83px 0px 51px 0px;
      @media screen and (max-width: 767px) {
        padding-top: 0px;
      }
      h5 {
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
      .banner-btn {
        @media only screen and (max-width: 767px) {
          flex-wrap: wrap;
          justify-content: center;
        }
      }
    }
    .banner-bottom-client {
      .company-logo {
        width: 14%;
        @media (max-width: 767px) {
          width: 24%;
        }
        img {
          filter: grayscale(100%);
          transition: all 0.5s;
          opacity: 0.8;
          height: 110px;
          &:hover {
            filter: grayscale(0%);
            transition: all 0.5s;
          }
        }
      }
    }
  }
  .step-card-sec {
    padding: 60px 0;
    @media (max-width: 767px) {
      padding: 30px 0;
    }
    .step-card {
      border-radius: 0px 50px;
      min-height: 240px;
      margin-bottom: 40px;
      @media only screen and (max-width: 767px) {
        margin-bottom: 30px;
        min-height: auto;
        text-align: center;
      }
      span {
        @media only screen and (max-width: 767px) {
          margin: auto;
        }
      }
    }
  }
  .switch-nxa-sec {
    padding: 60px 0;
    @media (max-width: 767px) {
      padding: 30px 0;
    }
    .switch-nxa-card {
      .card-circle {
        margin: 0px auto;
        margin-top: -57px;
        margin-bottom: 15px;
      }
    }
  }
  .start-form-sec {
    padding: 60px 0;
    &::before {
      content: "";
      width: 259px;
      height: 264px;
      position: absolute;
      background: url(../../../public/assets/images/home-page-img/get-started-rectangles.svg)
        no-repeat;
      left: -40px;
      bottom: 55px;
      background-size: 100% 100%;
    }
    @media (max-width: 767px) {
      padding: 30px 0;
      text-align: center;
      &::before {
        display: none;
      }
      ul {
        padding-left: 0;
        padding-bottom: 30px;
        li {
          list-style: none;
        }
      }
    }
    .start-form-box {
      .login-via {
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        span {
          position: absolute;
          background: #fff;
          display: flex;
          padding: 5px 10px;
          justify-content: center;
          margin: 0 20px;
        }
      }
    }
  }
  .team-size-sec {
    padding: 60px 0;
    @media (max-width: 767px) {
      padding: 30px 0;
    }
  }
  .communication-features {
    padding: 60px 0;
    @media (max-width: 767px) {
      padding: 30px 0;
    }
    .feature-card {
      .card-circle {
        margin-top: -40px;
        margin-left: -37px;
      }
      @media (max-width: 767px) {
        text-align: center;
        .card-circle {
          margin-top: -40px;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 10px;
        }
      }
    }
  }
  .explore-products-sec​ {
    padding: 60px 0;
    @media (max-width: 767px) {
      padding: 30px 0;
    }
    .explore-products-box {
      border-radius: 18px 62px 0 50px;
      @media (max-width: 767px) {
      }
      .explore-products-box-img {
        border-radius: 18px 62px 0 50px;
      }
    }
  }
  .product-class-sec {
    padding: 60px 0;
    @media (max-width: 767px) {
      padding: 30px 0;
    }
  }
  .video-sec {
    background: linear-gradient(100deg, #fff, #d7d7d7);
    border-radius: 0 100px;
    min-height: auto;
    margin-top: 100px;
    padding: 90px 0 60px;
    @media (max-width: 767px) {
      padding: 30px 0;
    }
    video {
      border-radius: 30px;
    }
  }
  .as-seen-logo-sec {
    padding: 60px 0;
    min-height: auto;
    margin-bottom: 50px;
    border-radius: 0 100px;
    background: #fff;
    margin-top: 90px;
    position: relative;
    @media (max-width: 767px) {
      padding: 30px 0;
      .company-logo-main {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr);
      }
    }
    &::before {
      content: "";
      width: 100px;
      height: 100px;
      position: absolute;
      background: url(../../../public/assets/images/Rectangle43.png) no-repeat;
      left: -30px;
      top: -45px;
      background-size: 100% 100%;
      z-index: 1;
      @media (max-width: 767px) {
        display: none;
      }
      @media (min-width: 1600px) {
        bottom: 145px;
      }
    }
    &::after {
      content: "";
      width: 110px;
      height: 108px;
      position: absolute;
      right: 0;
      bottom: -65px;
      z-index: 1;
      background: url(../../../public/assets/images/home-page-img/as-seen-rectangle-2.svg)
        left top no-repeat;
      border-radius: 25px 0;

      @media (max-width: 767px) {
        display: none;
      }
    }
  }
  .footer-sec {
    padding: 60px 0 50px;
    background: #f5f5f5 !important;
    position: relative;
    @media (max-width: 767px) {
      padding: 30px 0;
    }

    .footer-input {
      background: #d8d8d8;
      border: none;
      min-height: 40px;
      padding: 10px 10px !important;
      border-radius: 6px;
      display: block;
      color: #6f8ba4;
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      font-weight: 400;
      line-height: 1.5;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      &:focus {
        border: none;
        outline: none;
        color: #495057;
      }
    }
  }
  .home-call-popup {
    position: fixed;
    left: auto;
    box-shadow: 0px 6px 10px rgb(0 0 0 / 50%);
    top: auto;
    bottom: 45px;
    right: 20px;
    .close-popup {
      position: absolute;
      right: 0;
    }
  }
}
