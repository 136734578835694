.w {
  &-15 {
    width: 15%;
  }
  &-17 {
    width: 17%;
  }
  &-20 {
    width: 20% !important;
  }
  &-22 {
    width: 22% !important;
  }
  &-24 {
    width: 24%;
  }
  &-26 {
    width: 26%;
  }
  &-30 {
    width: 30%;
  }
  &-35 {
    width: 35%;
  }
  &-40 {
    width: 40%;
  }
  &-50 {
    width: 50%;
  }
  &-55 {
    width: 55%;
  }
  &-60 {
    width: 60%;
  }
  &-63 {
    width: 63%;
  }
  &-65 {
    width: 65%;
  }
  &-70 {
    width: 70%;
  }
  &-74 {
    width: 74%;
  }
  &-75 {
    width: 75% !important;
  }
  &-76 {
    width: 76%;
  }
  &-80 {
    width: 80%;
  }
  &-82 {
    width: 82%;
  }
  &-85 {
    width: 85%;
  }
  &-90 {
    width: 90%;
  }
  &-95 {
    width: 95% !important;
  }
  &--2 {
    width: 2px;
  }
  &--9 {
    width: 9px;
  }
  &--10 {
    width: 10px;
  }
  &--13 {
    width: 13px;
  }
  &--14 {
    width: 14px;
  }
  &--15 {
    width: 15px;
  }
  &--16 {
    width: 16px;
  }
  &--17 {
    width: 17px;
  }
  &--18 {
    width: 18px !important;
  }
  &--20 {
    width: 20px !important;
  }
  &--22 {
    width: 22px;
  }
  &--23 {
    width: 23px;
  }
  &--24 {
    width: 24px;
  }
  &--25 {
    width: 25px !important;
  }
  &--26 {
    width: 26px;
  }
  &--28 {
    width: 28px;
  }
  &--30 {
    width: 30px;
  }
  &--31 {
    width: 31px;
  }
  &--35 {
    width: 35px;
  }
  &--36 {
    width: 36px;
  }
  &--40 {
    width: 40px;
  }
  &--42 {
    width: 42px;
  }
  &--45 {
    width: 45px;
  }
  &--46 {
    width: 46px;
  }
  &--50 {
    width: 50px;
  }
  &--55 {
    width: 55px;
  }
  &--58 {
    width: 58px !important;
  }
  &--60 {
    width: 60px;
  }
  &--65 {
    width: 65px;
  }
  &--70 {
    width: 70px !important;
  }
  &--75 {
    width: 75px;
  }
  &--80 {
    width: 80px;
  }
  &--85 {
    width: 85px;
  }
  &--95 {
    width: 95px;
  }
  &--100 {
    width: 100px;
  }

  &--105 {
    width: 105px;
  }
  &--110 {
    width: 110px;
  }
  &--112 {
    width: 112px;
  }
  &--115 {
    width: 115px;
  }
  &--120 {
    width: 120px;
  }
  &--130 {
    width: 130px;
  }
  &--140 {
    width: 140px;
  }
  &--147 {
    width: 147px;
  }

  &--153 {
    width: 153px;
  }
  &--160 {
    width: 160px;
  }
  &--170 {
    width: 170px;
  }
  &--172 {
    width: 172px;
  }
  &--175 {
    width: 175px;
  }
  &--176 {
    width: 176px;
  }
  &--180 {
    width: 180px;
  }
  &--186 {
    width: 186px;
  }
  &--190 {
    width: 190px;
  }
  &--200 {
    width: 200px;
  }
  &--215 {
    width: 215px;
  }
  &--223 {
    width: 223px;
  }
  &--240 {
    width: 240px;
  }
  &--260 {
    width: 260px;
  }
  &--270 {
    width: 270px;
  }
  &--280 {
    width: 280px;
  }
  &--365 {
    width: 365px;
  }
  &--725 {
    width: 725px;
  }
  &-min-40 {
    min-width: 40%;
  }
  &--min-20 {
    min-width: 20px;
  }
  &--min-25 {
    min-width: 25px;
  }
  &--min-35 {
    min-width: 35px;
  }
  &--min-30 {
    min-width: 30px;
  }
  &--min-40 {
    min-width: 40px;
  }
  &--min-45 {
    min-width: 45px;
  }
  &--min-50 {
    min-width: 50px !important;
  }
  &--min-60 {
    min-width: 60px !important;
  }
  &--min-70 {
    min-width: 70px !important;
  }
  &--min-80 {
    min-width: 80px;
  }
  &--min-90 {
    min-width: 90px;
  }
  &--min-95 {
    min-width: 95px;
  }
  &--min-100 {
    min-width: 100px;
  }
  &--min-110 {
    min-width: 110px;
  }
  &--min-116 {
    min-width: 116px;
  }
  &--min-120 {
    min-width: 120px !important;
  }
  &--min-135 {
    min-width: 135px;
  }
  &--min-140 {
    min-width: 140px;
  }
  &--min-144 {
    min-width: 144px;
  }
  &--min-166 {
    min-width: 166px;
  }
  &--min-170 {
    min-width: 170px;
  }
  &--min-190 {
    min-width: 190px;
  }
  &--min-220 {
    min-width: 220px;
  }
  &--min-274 {
    min-width: 274px;
  }
  &--min-290 {
    min-width: 290px;
  }
  &--min-320 {
    min-width: 320px;
  }
  &--min-380 {
    min-width: 380px;
  }
  &--min-auto {
    min-width: auto;
  }
  &--mx-25 {
    max-width: 25px;
  }
  &--mx-100 {
    max-width: 100px;
  }
  &--mx-120 {
    max-width: 120px;
  }
  &--mx-150 {
    max-width: 150px;
  }
  &--mx-180 {
    max-width: 180px;
  }
  &--mx-200 {
    max-width: 200px;
  }
  &--mx--374 {
    max-width: 374px;
  }
  &--mx-320 {
    max-width: 320px;
  }
  &--mx-420 {
    max-width: 420px;
  }
  &--mx-422 {
    max-width: 422px;
  }
  &--mx-700 {
    max-width: 700px;
  }
  &--mx-800 {
    max-width: 800px;
  }
  &--mx-24 {
    max-width: 24%;
  }
  &--mx-65 {
    max-width: 65%;
  }
  &--mx-70 {
    max-width: 70%;
  }
  &-auto {
    width: auto;
  }
  &--min-fit-content {
    min-width: fit-content;
  }
  &-fit-content {
    width: fit-content;
  }
  @media (max-width: 767px) {
    &-sm-100 {
      width: 100% !important;
    }
  }
}

@media screen and (min-width: 1035px) and (max-width: 1366px) {
  .w-small {
    &--425 {
      width: 425px;
    }
  }
}
