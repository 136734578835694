.custom-xxl {
  width: 75% !important;
  max-width: 75% !important;
  display: flex;
  align-items: center;
}
// .pricing-modal-width {
//   width: 100% !important;
//   max-width: 74% !important;
//   @media screen and (min-width: 1025px) and (max-width: 1280px) {
//     max-width: 95% !important;
//     top: 2% !important;
//   }
// }
.modal {
  &.show {
    overflow-y: auto !important;
    display: block !important;
    transition: opacity 0.15s linear;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .modal-dialog {
    top: 5% !important;
    height: auto;
    // height: 100%;
    // margin: 0 auto;
    // display: flex;
    // align-items: flex-start;
    // width: 55%;
    width: 100%;
    // border-radius: 15px;
    // top: 10% !important;
    // height: auto;
    &.modal--md {
      max-width: 35%;
      &-2 {
        max-width: 42%;
      }
    }
    &.modal--lg {
      max-width: 55%;
    }
    &.modal--xl {
      max-width: 75%;
      @media screen and (min-width: 1025px) and (max-width: 1280px) {
        max-width: 95% !important;
        top: 2% !important;
      }
    }
    .modal-content {
      .custom-modal {
        width: 100%;
        .input--group {
          .input {
            font-size: 16px;
            border: none;
            border-radius: 0px;
            padding: 6px 6px;
            border-bottom: 1px solid;
          }
        }
        .modal-scroll {
          @media screen and (min-width: 1035px) and (max-width: 1366px) {
            height: 405px;
            overflow-y: auto;
          }
        }
        .call-info {
          .tag-popup {
            input::placeholder {
              color: #757575;
              opacity: 1;
            }
            .tag-list {
              .tag-search {
                span {
                  position: absolute;
                  top: 10px;
                  left: 10px;
                }
              }
              .modal-scroll {
                height: 220px;
                padding-right: 15px;
                margin-right: -15px;
              }
            }
            .choose-tag-color {
              position: absolute;
              top: 3px;
              left: 4px;
            }
          }
          .assign-popup {
            input::placeholder {
              color: #757575;
              opacity: 1;
            }
            .tag-search {
              span {
                position: absolute;
                top: 10px;
                left: 10px;
              }
            }
            .modal-scroll {
              height: 220px;
              padding-right: 15px;
              margin-right: -15px;
            }
          }
          .message-contact-popup {
            .input--group {
              .input {
                &.PhoneInput--disabled {
                  .PhoneInputCountry {
                    background: #e9ecef;
                    color: $white;
                    .PhoneInputCountrySelect {
                      background: transparent;
                    }
                  }
                }
                .PhoneInputCountryIconImg {
                  width: 30px;
                  margin-right: 8px;
                  border-radius: 50px;
                  height: 30px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  overflow: hidden;
                  object-fit: cover;
                  margin-top: 1px;
                }
              }
            }
          }
          .schedule-call-popup {
            .calender {
              .rdp {
                margin: 0;
                .rdp-caption_start {
                  width: 100%;
                  .rdp-caption_label {
                    padding: 0;
                    font-family: "sf_ui_displaybold";
                    color: #212529;
                  }
                  .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
                    outline: none;
                  }
                  .rdp-button {
                    border: none;
                    outline: none;
                  }
                  .rdp-button:focus-visible:not([disabled]) {
                    border: none;
                  }
                }
                .rdp-table {
                  max-width: 100%;
                  width: 100%;
                  border-spacing: 0px 5px;
                  .rdp-head {
                    .rdp-head_row {
                      .rdp-head_cell {
                        color: #212529;
                        font-family: "sf_ui_displaybold";
                        letter-spacing: 1.1px;
                      }
                    }
                  }
                  .rdp-tbody {
                    .rdp-row {
                      .rdp-cell {
                        .rdp-day {
                          width: 37px;
                          height: 37px;
                          margin: 0 auto;
                        }
                      }
                    }
                  }
                }
              }
            }
            .available-time-slote {
              .custom-time-input {
                width: 33%;
                @media screen and (min-width: 1025px) and (max-width: 1280px) {
                  width: 50%;
                }
                .custom-time-input-icon {
                  position: absolute;
                  top: 12px;
                  right: 10px;
                  gap: 10px;
                }
              }
            }
          }
        }
        .meeting-page-modal {
          .start-meeting {
            .input--group {
              .input {
                color: #b6b9c4;
                border-bottom: 1px solid #b6b9c4 !important;
              }
            }
          }
          .Join-meeting {
            .input--group {
              .input {
                color: #b6b9c4;
                border-bottom: 1px solid #b6b9c4 !important;
              }
            }
          }
          .schedule-meeting {
            .input--group {
              .meeting-title-close-icon {
                position: absolute;
                top: 29px;
                right: 8px;
              }
              .input {
                &:focus {
                  border-color: $black !important;
                }
                .css-13cymwt-control {
                  border: none;
                  min-height: 34px;
                  height: 34px;
                  position: absolute;
                  top: -2px;
                  width: 100%;

                  .css-1fdsijx-ValueContainer {
                    padding: 0;
                    height: 34px;
                  }
                }
                .css-t3ipsp-control {
                  border: none;
                  height: 34px;
                  min-height: auto;
                  position: absolute;
                  top: -2px;
                  width: 100%;
                  box-shadow: none;
                  &:hover {
                    border: none;
                  }
                  .css-1fdsijx-ValueContainer {
                    padding: 0;
                  }
                }
                .css-3w2yfm-ValueContainer {
                  padding: 0;
                }
                .css-qbdosj-Input {
                  grid-template-columns: 100%;
                  padding: 0;
                  margin: 0;
                  height: 34px;
                  input {
                    grid-area: unset !important;
                    height: 34px;
                  }
                }
                .css-166bipr-Input {
                  grid-template-columns: 100%;
                  input {
                    grid-area: unset !important;
                  }
                }
                .css-1nmdiq5-menu {
                  left: 0;
                  .css-1n6sfyn-MenuList {
                    @media screen and (min-width: 1035px) and (max-width: 1366px) {
                      max-height: 165px;
                    }
                    .css-d7l1ni-option {
                      background-color: $orange;
                      color: $white;
                    }
                  }
                }
              }
            }
            .meeting-setting {
              .input--group {
                .input {
                  font-size: 15px;
                  border-radius: 6px;
                  border: 1px solid $dark-gray-1;
                  min-height: auto;
                  &:focus {
                    border-color: $orange !important;
                  }
                }
              }
            }
          }
          .share-meeting-popup {
            .invite-team {
              .input {
                .css-13cymwt-control {
                  border-color: $gray-c7;
                  .btn--orange {
                    display: none !important;
                  }
                  .css-1fdsijx-ValueContainer {
                    padding: 0;
                    height: 45px;
                  }
                  .css-1p3m7a8-multiValue {
                    border-radius: 5px;
                  }
                  .css-1jqq78o-placeholder {
                    display: block;
                    margin-left: 6px;
                    z-index: 1;
                  }
                }
                .css-t3ipsp-control {
                  box-shadow: none;
                  min-height: 45px;
                  border-color: $orange;
                  .btn--orange {
                    display: none !important;
                  }

                  .css-1fdsijx-ValueContainer {
                    padding: 0 0 0 5px;
                  }
                  .css-1jqq78o-placeholder {
                    display: none;
                    z-index: 1;
                  }
                }
                .css-3w2yfm-ValueContainer {
                }
                .css-qbdosj-Input {
                  grid-template-columns: 100%;
                  padding: 0;
                  margin: 0;
                  height: 45px;
                  input {
                    grid-area: unset !important;
                    height: 46px;
                    border-radius: 5px;
                  }
                }
                .css-166bipr-Input {
                  grid-template-columns: 100%;
                  input {
                    grid-area: unset !important;
                  }
                }
                .css-1nmdiq5-menu {
                  left: 0;

                  .css-1n6sfyn-MenuList {
                    @media screen and (min-width: 1035px) and (max-width: 1366px) {
                      max-height: 165px;
                    }
                    .css-d7l1ni-option {
                      background-color: $gray-d9;
                    }
                  }
                }
              }
              .search {
                position: absolute;
                bottom: 12px;
                left: 13px;
              }
              // .input::-webkit-input-placeholder {
              //   font-family: FontAwesome;
              //   font-weight: normal;
              //   overflow: visible;
              //   vertical-align: top;
              //   display: inline-block !important;
              //   padding-left: 5px;
              //   padding-top: 2px;
              //   color: #000;
              // }
            }
          }
          .invite-meeting-members-modal {
            .input--group {
              .css-13cymwt-control,
              .css-t3ipsp-control {
                min-height: 45px;
                border-color: #6f8ba4 !important;
              }
              .css-1nmdiq5-menu {
                z-index: 2;
              }
              .css-1dimb5e-singleValue {
                z-index: 1;
              }
              .css-d7l1ni-option {
                background-color: $orange;
                color: $white;
              }
              .css-d7l1ni-option {
                background-color: $orange;
              }
            }
          }
        }
        .chat-page-modal {
          .create-team {
            .input--group {
              .input {
                color: $dark-gray-1;
              }
              .css-13cymwt-control {
                border: none;
                min-height: 34px;
                border-bottom: 1px solid #ddd;
                border-radius: 0;

                .css-1fdsijx-ValueContainer {
                  padding: 0;
                  height: 34px;
                }
              }
              .css-t3ipsp-control {
                border: none;
                min-height: 34px;

                border-bottom: 1px solid #6f8ba4;
                border-radius: 0;

                box-shadow: none;

                .css-1fdsijx-ValueContainer {
                  padding: 0 0 0 5px;
                }
              }
              .css-3w2yfm-ValueContainer {
                padding: 0;
              }
              .css-qbdosj-Input {
                grid-template-columns: 100%;
                padding: 0;
                margin: 0;
                height: 34px;
                input {
                  grid-area: unset !important;
                  height: 34px;
                }
              }
              .css-166bipr-Input {
                grid-template-columns: 100%;
                input {
                  grid-area: unset !important;
                }
              }
              .css-1nmdiq5-menu {
                left: 0;
                .css-1n6sfyn-MenuList {
                  @media screen and (min-width: 1035px) and (max-width: 1366px) {
                    max-height: 165px;
                  }
                  .css-d7l1ni-option {
                    background-color: $orange;
                    color: $white;
                  }
                }
              }
            }
          }
        }
        .manage-plan {
          .plan-popup {
            .rechage-plan-box {
              max-width: 340px !important;
              min-height: 554px;
              background: $white;
              border-radius: 10px;
              width: 100%;
              box-shadow: 0 0 20px rgb(46 59 125 / 5%);
              padding-bottom: 20px;
              &:first-child {
                .box-header {
                  padding: 15px 0;
                }
                .body-height {
                  height: 443px;
                }

                &.active {
                  .box-header {
                    span {
                      color: #ec4444;
                      &:last-child {
                        color: $white;
                      }
                    }
                  }
                }
                .box-header {
                  background: #ffd166 !important;
                  border-top-left-radius: 10px;
                  border-top-right-radius: 10px;
                  margin-bottom: 10px;
                  span {
                    color: #ffd166;
                    &:last-child {
                      color: $gray-66;
                    }
                  }
                }
              }
              &:nth-child(2) {
                .body-height {
                  height: 499px;
                }
                &.active {
                  .body-height {
                    height: 479px;
                  }
                }
              }
              &:nth-child(3) {
                .body-height {
                  height: 463px;
                }

                &.active {
                  .body-height {
                    height: 443px;
                  }
                  .box-header {
                    background: #457afe !important;
                    span {
                      color: #ffd166;
                      &:last-child {
                        color: $white;
                      }
                    }
                  }
                }
                .box-header {
                  span {
                    color: $orange;
                    &:last-child {
                      color: $gray-66;
                    }
                  }
                }
              }
              &:nth-child(4) {
                .body-height {
                  height: 523px;
                }
                &.active {
                  .body-height {
                    height: 503px;
                  }
                  .box-header {
                    background: #457afe !important;
                    span {
                      color: $white !important;
                    }
                  }
                }
                .box-header {
                  padding: 10px 0;
                }
              }
              &.active {
                z-index: 8;
                box-shadow: 0 0 20px rgba(46, 59, 125, 0.23);
                .box-header {
                  border-top-left-radius: 10px;
                  border-top-right-radius: 10px;
                  padding: 15px 0px;
                  box-shadow: 0px 5px 15px rgb(0 0 0 / 15%);
                  margin-bottom: 10px;
                  background: #457afe;
                  color: $white;
                }
              }

              .box-header {
                padding-top: 15px;
              }
            }
          }
        }
        .subscription-plan-payment-details {
          .team-size {
            .MuiSlider-rail {
              height: 8px;
              border-radius: 25px;
              color: #c9c9c9;
            }
            .MuiSlider-track {
              color: #ea7414;
              height: 8px;
              border-radius: 25px;
            }
            .MuiSlider-markLabel {
              font-size: 18px;
              top: 20px;
            }
            .MuiSlider-thumb {
              margin-top: -7px;
              border: 2px solid #e7ebf0;
              background-color: #e7ebf0;
              height: 24px;
              width: 24px;
            }
            .css-1tfve6q-MuiSlider-mark {
              width: 0;
            }
            .MuiSlider-valueLabelOpen {
              background: #000;
              width: 32px;
              height: 32px;
              display: flex;
              transform: rotate(-45deg);
              align-items: center;
              border-radius: 50% 50% 50% 0;
              justify-content: center;
              position: absolute;
              top: -51px;
              right: -18px;
              &::before {
                display: none;
              }
              .MuiSlider-valueLabelLabel {
                color: #fff;
                transform: rotate(45deg);
              }
            }
          }
          .save-cards-grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            max-height: 130px;
            overflow-y: auto;
            gap: 15px;
            padding-right: 15px;
            margin-right: -30px;
            .form-check {
              input {
                [type="radio"] {
                  width: 20px;
                  height: 20px;
                  padding: 4px;
                }
                [type="radio"]:checked {
                  background-color: $orange;
                }
                &.form-check-input {
                  border: 2px solid $black;
                  // background-color: $white;
                }
              }
              .form-check-label {
                padding-top: 6px;
                padding-left: 10px;
              }
            }
          }
          .voucher-collapse {
            .voucher {
              background-color: #fff;
              .btn-redeem {
                color: $white;
                background-color: $orange;
              }
              &.select-voucher {
                background: $orange;
                .btn-redeem {
                  color: $orange;
                  background-color: $white;
                }
              }
            }
          }
        }
        .voicemail-modal {
          .personal-voicemail-setting {
            .recording-player {
              .rhap_container {
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                line-height: 1;
                font-family: inherit;
                width: 100%;
                padding: 0;
                background-color: #fff;
                box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
                position: relative;
                min-height: 58px;
                box-shadow: none;
                .rhap_horizontal-reverse {
                  flex-direction: row-reverse;
                  display: flex;
                  flex: 1 1 auto;
                  .rhap_progress-section {
                    position: absolute;
                    width: 57%;
                    left: 67px;
                    top: 19px;
                    display: flex;
                    flex: 3 1 auto;
                    align-items: center;
                    .rhap_progress-container {
                      display: flex;
                      align-items: center;
                      height: 20px;
                      flex: 1 0 auto;
                      align-self: center;
                      margin: 0 calc(10px + 1%);
                      cursor: pointer;
                      -webkit-user-select: none;
                      .rhap_progress-bar-show-download {
                        background-color: rgba(221, 221, 221, 0.5);
                        box-sizing: border-box;
                        position: relative;
                        z-index: 0;
                        width: 100%;
                        height: 5px;
                        border-radius: 2px;
                        .rhap_progress-indicator {
                          box-sizing: border-box;
                          position: absolute;
                          z-index: 3;
                          width: 20px;
                          height: 20px;
                          margin-left: -10px;
                          top: -8px;
                          background: $orange;
                          border-radius: 50px;
                          box-shadow: rgba(134, 134, 134, 0.5) 0 0 5px;
                        }
                        .rhap_progress-filled {
                          height: 100%;
                          position: absolute;
                          z-index: 2;
                          background-color: $orange;
                          border-radius: 2px;
                        }
                      }
                    }
                    .rhap_time {
                      color: #aaaa;
                      border: 1px solid #aaaa;
                      padding-top: 4px;
                      padding-bottom: 4px;
                      padding-left: 6px;
                      padding-right: 6px;
                      border-radius: 11px;
                    }
                  }
                  .rhap_controls-section {
                    margin-right: 0px;
                    display: flex;
                    flex: 1 1 auto;
                    justify-content: space-between;
                    align-items: center;
                    .rhap_additional-controls {
                      display: none;
                      flex: 1 0 auto;
                    }
                    .rhap_main-controls {
                      position: absolute;
                      left: 10px;
                      flex: 0 1 auto;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      top: 8px;
                      height: 46px;
                    }
                    .rhap_volume-controls {
                      position: absolute;
                      right: 22px;
                      width: 17%;
                      top: 16px;
                      display: flex;
                      flex: 1 0 auto;
                      justify-content: flex-end;
                      align-items: center;
                      .rhap_volume-container {
                        display: flex;
                        align-items: center;
                        flex: 0 1 100px;
                        -webkit-user-select: none;
                        .rhap_volume-button {
                          color: #ea7414;
                          flex: 0 0 26px;
                          font-size: 26px;
                          width: 26px;
                          height: 26px;
                          margin-right: 6px;
                          background-color: transparent;
                          border: none;
                          padding: 0;
                          overflow: hidden;
                        }
                        .rhap_volume-bar-area {
                          display: flex;
                          align-items: center;
                          width: 100%;
                          height: 14px;
                          cursor: pointer;

                          background: #eeeeee;
                          box-sizing: border-box;
                          position: relative;
                          width: 100%;
                          height: 4px;
                          border-radius: 2px;
                          .rhap_volume-indicator {
                            background: #ea7414;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .record-voicemail {
            .recording-upload-box {
              @media screen and (min-width: 1025px) and (max-width: 1099px) {
                width: 100%;
              }
              @media screen and (min-width: 1100px) and (max-width: 1280px) {
                width: 85%;
              }
              @media screen and (min-width: 1281px) and (max-width: 1537px) {
                width: 70%;
              }

              .recording-wave {
                width: 65%;
                // @media screen and (min-width: 1025px) and (max-width: 1099px) {
                //   width: 100%;
                // }
                // @media screen and (min-width: 1100px) and (max-width: 1280px) {
                //   width: 85%;
                // }
                // @media screen and (min-width: 1281px) and (max-width: 1537px) {
                //   width: 70%;
                // }

                .loader1 {
                  display: inline-block;
                  font-size: 10px;
                  padding: 0px;
                }

                .loader1 span {
                  vertical-align: middle;
                  border-radius: 3px;
                  display: inline-block;
                  width: 4px;
                  height: 25px;
                  margin: 3px 2px;
                  -webkit-animation: loader1 0.8s linear infinite alternate;
                  animation: loader1 0.8s linear infinite alternate;
                }

                .loader1 span:nth-child(1) {
                  -webkit-animation-delay: -1s;
                  animation-delay: -1s;
                  background: #b6d7a8;
                }

                .loader1 span:nth-child(2) {
                  -webkit-animation-delay: -0.8s;
                  animation-delay: -0.8s;
                  background: #b6d7a8;
                }

                .loader1 span:nth-child(3) {
                  -webkit-animation-delay: -0.26666s;
                  animation-delay: -0.26666s;
                  background: #fbbd92;
                }

                .loader1 span:nth-child(4) {
                  -webkit-animation-delay: -0.8s;
                  animation-delay: -0.8s;
                  background: #ff7e7e;
                }

                .loader1 span:nth-child(5) {
                  -webkit-animation-delay: -1s;
                  animation-delay: -1s;
                  background: #ff7e7e;
                }

                .loader1 span:nth-child(6) {
                  -webkit-animation-delay: -1s;
                  animation-delay: -1s;
                  background: #b6d7a8;
                }

                .loader1 span:nth-child(7) {
                  -webkit-animation-delay: -0.8s;
                  animation-delay: -0.8s;
                  background: #b6d7a8;
                }

                .loader1 span:nth-child(8) {
                  -webkit-animation-delay: -0.26666s;
                  animation-delay: -0.26666s;
                  background: #fbbd92;
                }

                .loader1 span:nth-child(9) {
                  -webkit-animation-delay: -0.8s;
                  animation-delay: -0.8s;
                  background: #ff7e7e;
                }

                .loader1 span:nth-child(10) {
                  -webkit-animation-delay: -1s;
                  animation-delay: -1s;
                  background: #ff7e7e;
                }

                .loader1 span:nth-child(11) {
                  -webkit-animation-delay: -0.5s;
                  animation-delay: -0.5s;
                  background: #fbbd92;
                }

                .loader1 span:nth-child(12) {
                  -webkit-animation-delay: -0.8s;
                  animation-delay: -0.8s;
                  background: #ff7e7e;
                }

                .loader1 span:nth-child(13) {
                  -webkit-animation-delay: -1s;
                  animation-delay: -1s;
                  background: #ff7e7e;
                }

                .loader1 span:nth-child(14) {
                  -webkit-animation-delay: -0.5s;
                  animation-delay: -0.5s;
                  background: #fbbd92;
                }

                .loader1 span:nth-child(15) {
                  -webkit-animation-delay: -0.8s;
                  animation-delay: -0.8s;
                  background: #b6d7a8;
                }

                .loader1 span:nth-child(16) {
                  -webkit-animation-delay: -1s;
                  animation-delay: -1s;
                  background: #ff7e7e;
                }

                .loader1 span:nth-child(17) {
                  -webkit-animation-delay: -0.8s;
                  animation-delay: -0.8s;
                  background: #ff7e7e;
                }

                .loader1 span:nth-child(18) {
                  -webkit-animation-delay: -1s;
                  animation-delay: -1s;
                  background: #b6d7a8;
                }

                @keyframes loader1 {
                  from {
                    transform: scale(0, 0);
                  }

                  to {
                    transform: scale(1, 1);
                  }
                }

                @-webkit-keyframes loader1 {
                  from {
                    -webkit-transform: scale(0, 0);
                  }

                  to {
                    -webkit-transform: scale(1, 1);
                  }
                }
              }
            }
            // wave css end here

            .rhap_container {
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              line-height: 1;
              font-family: inherit;
              width: 100%;
              padding: 0;
              background-color: #fff;
              box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
              position: relative;
              min-height: 58px;
              box-shadow: none;
              .rhap_horizontal-reverse {
                flex-direction: row-reverse;
                display: flex;
                flex: 1 1 auto;
                .rhap_progress-section {
                  position: absolute;
                  width: 57%;
                  left: 67px;
                  top: 19px;
                  display: flex;
                  flex: 3 1 auto;
                  align-items: center;
                  .rhap_progress-container {
                    display: flex;
                    align-items: center;
                    height: 20px;
                    flex: 1 0 auto;
                    align-self: center;
                    margin: 0 calc(10px + 1%);
                    cursor: pointer;
                    -webkit-user-select: none;
                    .rhap_progress-bar-show-download {
                      background-color: rgba(221, 221, 221, 0.5);
                      box-sizing: border-box;
                      position: relative;
                      z-index: 0;
                      width: 100%;
                      height: 5px;
                      border-radius: 2px;
                      .rhap_progress-indicator {
                        box-sizing: border-box;
                        position: absolute;
                        z-index: 3;
                        width: 20px;
                        height: 20px;
                        margin-left: -10px;
                        top: -8px;
                        background: $orange;
                        border-radius: 50px;
                        box-shadow: rgba(134, 134, 134, 0.5) 0 0 5px;
                      }
                      .rhap_progress-filled {
                        height: 100%;
                        position: absolute;
                        z-index: 2;
                        background-color: $orange;
                        border-radius: 2px;
                      }
                    }
                  }
                  .rhap_time {
                    color: #aaaa;
                    border: 1px solid #aaaa;
                    padding-top: 4px;
                    padding-bottom: 4px;
                    padding-left: 6px;
                    padding-right: 6px;
                    border-radius: 11px;
                  }
                }
                .rhap_controls-section {
                  margin-right: 0px;
                  display: flex;
                  flex: 1 1 auto;
                  justify-content: space-between;
                  align-items: center;
                  .rhap_additional-controls {
                    display: none;
                    flex: 1 0 auto;
                  }
                  .rhap_main-controls {
                    position: absolute;
                    left: 10px;
                    flex: 0 1 auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    top: 8px;
                    height: 46px;
                  }
                  .rhap_volume-controls {
                    position: absolute;
                    right: 22px;
                    width: 17%;
                    top: 16px;
                    display: flex;
                    flex: 1 0 auto;
                    justify-content: flex-end;
                    align-items: center;
                    .rhap_volume-container {
                      display: flex;
                      align-items: center;
                      flex: 0 1 100px;
                      -webkit-user-select: none;
                      .rhap_volume-button {
                        color: #ea7414;
                        flex: 0 0 26px;
                        font-size: 26px;
                        width: 26px;
                        height: 26px;
                        margin-right: 6px;
                        background-color: transparent;
                        border: none;
                        padding: 0;
                        overflow: hidden;
                      }
                      .rhap_volume-bar-area {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        height: 14px;
                        cursor: pointer;
                        margin-top: 6px;
                        margin-left: 4px;
                        background: #eeeeee;
                        box-sizing: border-box;
                        position: relative;
                        width: 100%;
                        height: 4px;
                        border-radius: 2px;
                        .rhap_volume-indicator {
                          background: #ea7414;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .add-edit-holiday-modal {
            .holiday-date-picker {
              .fa-times {
                position: absolute;
                top: 17px;
                left: 10px;
              }
            }
          }
        }
        .recharge-modal {
          .choose-pyment-card {
            a {
              &.input {
                &::after {
                  content: "";
                  display: inline-block;
                  height: 0.4em;
                  right: 0.5em;
                  position: absolute;
                  top: 1.1em;
                  transform: rotate(135deg);
                  vertical-align: top;
                  width: 0.4em;
                  color: #cccccc;
                  border-right: 0.15em solid #6f8ba4;
                  border-left: 0em solid #ddd0;
                  border-top: 0.15em solid #6f8ba4;
                  border-bottom: 0em solid #85121200;
                }
              }
            }

            .user-card {
              box-shadow: 0px 5px 20px rgb(0 0 0 / 15%);
              background-image: linear-gradient(
                  30deg,
                  rgba(255, 255, 255, 0) 70%,
                  rgba(255, 255, 255, 0.2) 70%
                ),
                linear-gradient(
                  45deg,
                  rgba(255, 255, 255, 0) 75%,
                  rgba(255, 255, 255, 0.2) 75%
                ),
                linear-gradient(
                  60deg,
                  rgba(255, 255, 255, 0) 80%,
                  rgba(255, 255, 255, 0.2) 80%
                );
            }
          }
        }
        .nxa-wallet-modal {
          .nxa-wallet-tab {
            .nav-link {
              background: none;
              border: none;
              border-bottom: 5px solid transparent;
              padding: 15px 25px 20px;
              color: #777;
              font-size: 14px;
              margin-bottom: -1px;
              margin-right: 15px;
              &:last-child {
                margin-right: 0;
              }
              &.active {
                color: $orange;
                border-bottom: 5px solid $orange !important;
              }
              &:hover {
                color: $orange;
                border-bottom: 5px solid $orange !important;
              }
              &:focus {
                outline: none;
              }
              @media screen and (min-width: 1025px) and (max-width: 1280px) {
                padding: 15px 20px 20px;
                margin-right: 10px;
              }
            }
          }
          .day-week-dropdown {
            .list-unstyled {
              top: 33px;
              position: absolute;
              right: 0px;
              box-shadow: 0px 0px 3px rgb(170 178 200 / 45%);
              li {
                .dropdown-item {
                  &:hover,
                  &:focus {
                    background: transparent;
                  }
                }
              }
            }
          }
          .recharge-status {
            font-size: 20px;
            @media screen and (min-width: 1025px) and (max-width: 1280px) {
              font-size: 15px;
            }
          }
          .balance-table {
            &.table-custom {
              height: 200px;
              overflow: auto;
              box-shadow: 0px 2px 7px 2px rgba(0, 0, 0, 0.1);
              border-radius: 10px;

              .table {
                border-spacing: 0 !important;
                border-radius: 8px 8px 0 0;
                thead {
                  tr {
                    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.1))
                      drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1));
                    th {
                      &:first-child {
                        border-radius: 10px 0px 0px 0px;
                      }
                      &:last-child {
                        border-radius: 0px 10px 0px 0px;
                      }
                    }
                  }
                }
                tbody {
                  tr {
                    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.1))
                      drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1));

                    td:first-of-type {
                      border-top-left-radius: 0px;
                      border-bottom-left-radius: 0px;
                    }
                    td:last-of-type {
                      border-top-right-radius: 0px;
                      border-bottom-right-radius: 0px;
                    }
                  }
                }
              }
            }
          }
          .nxa-wallet-reachage-tab {
            .input--group {
              .css-13cymwt-control,
              .css-t3ipsp-control {
                min-height: 45px;
                border-color: #6f8ba4 !important;
              }
              .css-1nmdiq5-menu {
                z-index: 2;
              }
              .css-1dimb5e-singleValue {
                z-index: 1;
              }
              .css-d7l1ni-option,
              .css-tr4s17-option {
                background-color: $orange;
                color: $white;
              }
              .css-d7l1ni-option {
                background-color: $orange;
              }
              .dropdown {
                .input-scan-icon {
                  position: absolute;
                  top: 12px;
                  right: 7px;
                }
              }
            }
          }
          .nxa-wallet-transfer-tab {
            .input--group {
              .css-13cymwt-control,
              .css-t3ipsp-control {
                min-height: 45px;
                border-color: #6f8ba4 !important;
              }
              .css-1nmdiq5-menu {
                z-index: 2;
              }
              .css-1dimb5e-singleValue {
                z-index: 1;
              }
              .css-d7l1ni-option,
              .css-tr4s17-option {
                background-color: $orange;
                color: $white;
              }
              .css-d7l1ni-option {
                background-color: $orange;
              }
              .dropdown {
                .input-scan-icon {
                  position: absolute;
                  top: 12px;
                  right: 7px;
                }
              }
            }
          }
        }
        .meeting-participants-modal {
          .tag-search {
            span {
              position: absolute;
              top: 10px;
              left: 10px;
            }
          }
          .modal-scroll {
            height: 220px;
            padding-right: 15px;
            margin-right: -15px;
          }
        }
        .assign-modal {
          .scroll {
            padding-right: 15px;
            margin-right: -15px;
          }
        }
        .forward-to-device-modal {
          .PhoneInput {
            .PhoneInputCountry {
              border-color: $gray-dd;
            }
          }
        }
        .number-setting-modal {
          .dropdown {
            .dropdown-menu {
              left: -135px;
            }
          }
        }
      }
    }
  }
}

/* sweet alert swal modal start here  */
.swal-modal {
  min-height: 200px;
}
.swal-title {
  font-size: 14px;
  color: #80808a;
  font-weight: normal;
  margin-bottom: 0;
  padding-bottom: 0;
  margin-top: 45px;
}
.swal-footer {
  display: flex;
  justify-content: center;
  margin-top: 0;
}
.swal-button {
  width: auto;
  min-width: 130px;
  height: 38px;
}
.swal-button--confirm {
  background-color: #f17c2b !important;
  color: #ffffff;
  box-shadow: 0px 0px 10px rgba(88, 88, 91, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
}
.swal-button--confirm:hover,
.swal-button--confirm:focus,
.swal-button--danger:not([disabled]):hover,
.swal-button--danger:not([disabled]):focus {
  background-color: #f17c2b;
  color: #ffffff;
  opacity: 0.8;
  box-shadow: 0px 0px 10px rgba(88, 88, 91, 0.15);
}
.swal-button--cancel {
  border: 1px solid #f8f9fe;
  background-color: #f8f9fe;
  display: flex;
  align-items: center;
  justify-content: center;
}
.swal-button--cancel:hover,
.swal-button--cancel:focus,
.swal-button--cancel:not([disabled]):hover,
.swal-button--cancel:not([disabled]):focus {
  opacity: 0.8;
  border: 1px solid #f8f9fe;
  background-color: #f8f9fe;
  box-shadow: 0px 0px 10px rgba(88, 88, 91, 0.15);
}

.remove-member .swal-title {
  font-family: "sf_ui_textmedium" !important;
  font-weight: inherit;
  font-size: 18px;
  color: #000;
}
.remove-member .swal-content p {
  background: #ffdd79;
  padding: 10px 10px;
  border-radius: 4px;
  color: #000;
  font-weight: normal;
}
.swal-footer {
  display: flex;
  justify-content: center;
  margin-top: 0;
}
.remove-member .swal-button.swal-button--confirm {
  background: #bf4428;
  border-color: #bf4428;
  box-shadow: none;
}

/* sweet alert swal modal end here  */
