input[type="radio"]:checked {
  background-color: #f17c2b;
}
input:-internal-autofill-selected {
  background-color: #fff;
}

.input--group {
  display: flex;
  flex-direction: column;
  label {
    color: #1c2f4b;
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    text-align: start;
    font-weight: 700;
  }
  .input {
    color: #6f8ba4;
    border-color: #000;
    border-radius: 8px;
    min-height: 45px;
    max-height: 45px;
    box-shadow: none;
    font-size: 15px;
    display: block;
    width: 100%;
    font-weight: 400;
    line-height: 1.5;
    background-color: #f5f9fd;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding: 6px 10px;
    border: 1px solid #6f8ba4;
    font-family: "DM Sans", sans-serif;
    position: relative;
    outline: none;
    &:focus {
      border-color: $orange !important;
      outline: none;
    }

    &:disabled,
    &[readonly] {
      background-color: #e9ecef;
      opacity: 1;
      color: #6f8ba4;
      border: 1px solid #6f8ba4;
    }
  }
  textarea {
    &.input {
      min-height: auto;
      max-height: unset;
    }
  }
  .srv-validation-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
    display: flex;
  }
  .PhoneInput {
    width: 100%;
    .PhoneInputCountry {
      padding-left: 40px;
      height: 45px;
      width: 100%;
      color: #6f8ba4;
      border: 1px solid #6f8ba4;
      border-radius: 8px;
      background: $white;
      .PhoneInputCountrySelect {
        padding: 0;
        border: none;
        width: 100%;
        border-radius: 8px;
        box-shadow: none;
        color: #000;
        height: 43px;
        max-height: 43px;
      }
      .PhoneInputCountryIcon {
        width: 31px;
        position: absolute;
        top: 39px;
        left: 23px;
        img {
          width: 30px;
          height: 30px;
          border-radius: 100px;
          object-fit: cover;
        }
      }
    }
    .PhoneInputInput {
      max-height: 43px !important;
      min-height: 43px !important;
      top: 30px;
      width: 70%;
      color: #000;
      border-radius: 8px;
      box-shadow: none;
      font-size: 15px;
      display: block;
      font-weight: 400;
      padding: 6px 8px;
      border: none;
      position: absolute;
      left: 55px;
      height: 43px;
      outline: none;
      font-family: "DM Sans", sans-serif;
    }
  }
  .dropdown {
    .dropdown-toggle {
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      max-height: 45px;
      min-height: 45px;
      color: #000;
      border: 1px solid #000;
      border-radius: 6px;
      font-size: 15px;
      padding: 6px 10px;
      width: 100%;
      span {
        position: absolute;
        top: 9px;
        right: 3px;
      }
    }
    .dropdown-menu {
      max-height: 200px;
      overflow: hidden auto;
      box-shadow: 0px 0px 3px rgb(170 178 200 / 45%);
      width: 100%;
      border: none;
      .dropdown-item {
        padding: 9px 12px;
        border-bottom: 1px solid #f0f5fb;
        color: #212529;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        img {
          width: 35px;
          margin-right: 10px;
          border-radius: 50px;
          height: 35px;
        }

        &:hover {
          background-color: #f0f5fb;
        }
      }
    }
  }
  &.daynamic-dropdown {
    .css-13cymwt-control {
      border: 1px solid #6f8ba4;
      border-radius: 10px;
      max-height: 42px;
      min-height: 42px;
      .css-1jqq78o-placeholder {
        z-index: 1;
      }
    }
    .css-t3ipsp-control {
      box-shadow: none;
      border: 1px solid #f17c2b;
      border-radius: 10px;

      max-height: 42px;
      min-height: 42px;
      .css-qbdosj-Input {
        input {
          width: unset !important;
        }
      }
    }
  }
}

@-moz-document url-prefix() {
  .PhoneInput select {
    background: #fff;
  }
}

@supports (-moz-appearance: none) {
  .PhoneInput select {
    background: #fff;
  }
}

@media screen and (-moz-images-in-menus: 0) {
  .PhoneInput select {
    background: #fff;
  }
}

@-moz-document url-prefix() {
  .PhoneInput select {
    background: #fff;
  }
}

@supports (-moz-appearance: none) {
  .PhoneInput select {
    background: #fff;
  }
}

@media screen and (-moz-images-in-menus: 0) {
  .PhoneInput select {
    background: #fff;
  }
}

.PhoneInput input:-webkit-autofill,
.PhoneInput input:-webkit-autofill:hover,
.PhoneInput input:-webkit-autofill:focus,
.PhoneInput input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
