/* loader start  */
/* #preloader {
    position: fixed;
    z-index: 1091;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0,.2);
  }
  
  #loader {
    display: block;
    position: relative;
    left: 50% !important;
    top: 50% !important;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #fff;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    background-color: #f17c2b !important;
  }
  
  #loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #fff;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
  }
  
  #loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #ffffff;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
  }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  } */
  
  /* loader end  */


  /* Spinners */

  .loader--spinner {
    margin: 0;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    background: #00000038;
    width: 100%;
    height: 100% !important ;
    display: flex;
    justify-content: center;
    align-items: center;
  }

.loader--spinner div {
  animation: loader--spinner 1.2s linear infinite;
  transform-origin: 30px 30px;
}

.loader--spinner div:after {
  display: block;
  position: absolute;
  top: 1px;
  left: 27px;
  border-radius: 50%;
  content: ' ';
  height: 50px;
  margin: 70px;
  width: 50px;
}

.loader--spinner div:nth-child(1) {
  animation-delay: -1.1s;
  transform: rotate(0deg);
}

.loader--spinner div:nth-child(2) {
  animation-delay: -1s;
  transform: rotate(30deg);
}

.loader--spinner div:nth-child(3) {
  animation-delay: -0.9s;
  transform: rotate(60deg);
}

.loader--spinner div:nth-child(4) {
  animation-delay: -0.8s;
  transform: rotate(90deg);
}

.loader--spinner div:nth-child(5) {
  animation-delay: -0.7s;
  transform: rotate(120deg);
}

.loader--spinner div:nth-child(6) {
  animation-delay: -0.6s;
  transform: rotate(150deg);
}

.loader--spinner div:nth-child(7) {
  animation-delay: -0.5s;
  transform: rotate(180deg);
}

.loader--spinner div:nth-child(8) {
  animation-delay: -0.4s;
  transform: rotate(210deg);
}

.loader--spinner div:nth-child(9) {
  animation-delay: -0.3s;
  transform: rotate(240deg);
}

.loader--spinner div:nth-child(10) {
  animation-delay: -0.2s;
  transform: rotate(270deg);
}

.loader--spinner div:nth-child(11) {
  animation-delay: -0.1s;
  transform: rotate(300deg);
}

.loader--spinner div:nth-child(12) {
  animation-delay: 0s;
  transform: rotate(330deg);
}

@keyframes loader--spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.loader--spinner div:after {
  background: #f17c2b;
}
